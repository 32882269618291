import getRadians from './get-radians'

// Radius - Radius of sphere
// Theta - angle about z-axis
// Phi - angle about y-axis
const getSphereCoordinates = (radius, theta, phi) => {
  const thetaRadians = getRadians(theta)
  const phiRadians = getRadians(phi)

  const x = radius * Math.sin(phiRadians) * Math.cos(thetaRadians)
  const y = radius * Math.sin(phiRadians) * Math.sin(thetaRadians)
  const z = radius * Math.cos(phiRadians)

  return [x, y, z]
}

export default getSphereCoordinates
