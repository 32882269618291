import { useContext } from 'react'
import EasterEggContext from '../../../context-providers/easter-egg/context/context.js'
import { MainContainer } from './styled-components/chest.js'

const Chest = () => {
  const { completedStep4 } = useContext(EasterEggContext)

  return (
    <MainContainer id="chest-item-discovered" open={completedStep4}>
      <img
        id="chest-top-item-discovered"
        src="https://res.cloudinary.com/marco-prouve/image/upload/v1658444069/portfolio-site/easter-eggs/treasure-chest-top_hsa8xc.webp"
        alt="Chest Top"
        width="256"
        height="89"
        loading="lazy"
        draggable="false"
      />
      <svg
        height="100%"
        width="100%"
        fill="#000000"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <path d="M0,0 L100,0 L92,100 L8,100 L0,0" />
      </svg>
      <img
        id="chest-bottom-item-discovered"
        src="https://res.cloudinary.com/marco-prouve/image/upload/v1658444069/portfolio-site/easter-eggs/treasure-chest-bottom_hodf55.webp"
        alt="Chest Bottom"
        width="256"
        height="112"
        loading="lazy"
        draggable="false"
      />
    </MainContainer>
  )
}

export default Chest
