import { useState, useEffect, useRef, useContext } from 'react'
import ThemeContext from '../../../context-providers/theme/context/context.js'
import PROJECTS from './constants/projects.js'
import ScrollIndicator from '../../../reuseable/scroll-indicator/scroll-indicator.js'
import Squares from '../../../reuseable/squares/squares.js'
import {
  MainContainer,
  SectionContainer,
  SectionTitle,
  ProjectDates,
  SectionSubtitle,
  ProjectsContainer,
  Carousel,
  Slider,
  SliderItem,
  Project,
  ProjectLogoContainer,
  ProjectLogo,
  ProjectName,
  ProjectDesc,
  Links,
  Link,
  LinkIcon,
  LinkText
} from './styled-components/experience.js'

const Experience = () => {
  const { lightTheme } = useContext(ThemeContext)
  const sectionRef = useRef(null)
  const sliderRef = useRef(null)
  const [scrolledOver, setScrolledOver] = useState(false)
  const [activeProject, setActiveProject] = useState('n3twork')
  const selectedProject = PROJECTS.find((project) => project.id === activeProject)
  const TRANSLATE_DELTA = 100 / PROJECTS.length

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const windowBottomScrollY = innerHeight + pageYOffset

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClickSquare = (index) => {
    const project = PROJECTS.find((project) => project.index === index)
    const translatePercent = index * TRANSLATE_DELTA

    if (sliderRef?.current) {
      sliderRef.current.style.transform = `translateX(-${translatePercent}%)`

      setActiveProject(project.id)
    }
  }

  return (
    <MainContainer id="experience">
      <SectionContainer ref={sectionRef} scrolledOver={scrolledOver}>
        <SectionTitle lightTheme={lightTheme}>Experience</SectionTitle>
        <SectionSubtitle lightTheme={lightTheme}>
          I&#39;ve excelled at architecting full-stack (MERN) applications, from conceptualization
          to users and revenue, always with scalability and user experience in mind.
        </SectionSubtitle>

        <ProjectsContainer>
          <Carousel>
            <Slider ref={sliderRef} count={PROJECTS.length}>
              {PROJECTS.map((project, i) => {
                const { logo, title, dates, desc, color, links } = project

                return (
                  <SliderItem key={`experience-carousel-${i}`}>
                    <Project>
                      <ProjectLogoContainer color={color}>
                        <ProjectLogo style={{ backgroundImage: `url(${logo})` }} />
                      </ProjectLogoContainer>
                      <ProjectName color={color}>{title}</ProjectName>
                      <ProjectDates color={color}>{dates}</ProjectDates>
                      <ProjectDesc lightTheme={lightTheme}>{desc}</ProjectDesc>
                      <ScrollIndicator color={color} style={{ paddingLeft: '5px' }} />
                      <Links>
                        {links.map((link, i) => {
                          return (
                            <a
                              key={`experience-project-link-${i}`}
                              href={link.type === 'email' ? `mailto:${link.link}` : link.link}
                              target="_blank"
                              rel="noreferrer"
                              aria-label={link.text}>
                              <Link color={color} hoverWidth={link.buttonHoverWidth}>
                                <LinkIcon
                                  src={link.icon}
                                  alt="Link Icon"
                                  width="128"
                                  height="128"
                                  loading="lazy"
                                  style={link.text === 'iOS' ? { top: '47%' } : {}}
                                />
                                <LinkText>{link.text}</LinkText>
                              </Link>
                            </a>
                          )
                        })}
                      </Links>
                    </Project>
                  </SliderItem>
                )
              })}
            </Slider>
          </Carousel>

          <Squares
            identifier="experience"
            count={5}
            color={lightTheme ? '#333' : '#eee'}
            size={'20px'}
            activeSquare={selectedProject.index}
            activeColor={selectedProject.color}
            style={{ marginTop: '2rem', textAlign: 'center' }}
            squareStyle={{ cursor: 'pointer' }}
            handleClick={handleClickSquare}
          />
        </ProjectsContainer>
      </SectionContainer>
    </MainContainer>
  )
}

export default Experience
