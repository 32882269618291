import styled from 'styled-components'

export const SectionTitleContainer = styled.div`
  text-align: left;
`

export const SectionContentTitle = styled.span`
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  font-family: 'Bungee', cursive;
  font-size: 1.6rem;
  font-weight: 100;
  line-height: 2.1rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
`

export const SectionContentSubtitle = styled.p`
  display: block;
  margin-top: 0.5rem;
  text-align: left;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  white-space: pre-line;

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & > img {
    display: block;
    margin: 1.8rem auto 0.3rem auto;
    height: 2rem;
    width: auto;
    transform: ${({ completedStep3 }) => (completedStep3 ? ' scale(0)' : 'scale(1)')};
    transition: transform 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: ${({ completedStep3 }) => (completedStep3 ? 'scale(0)' : 'scale(1.1)')};
    }

    &:active {
      transform: ${({ completedStep3 }) => (completedStep3 ? 'scale(0)' : 'scale(1)')};
    }
  }
`
