import { useState, useEffect } from 'react'
import Typewriter from '../../../../../reuseable/typewriter/typewriter.js'
import { MainContainer, TextContainer, Title, Subtitle } from './styled-components/scene-overlay.js'
import config from '../../../../../../config.js'

// eslint-disable-next-line react/prop-types
const SceneOverlay = ({ loadingComplete }) => {
  const [beginTyping, setBeginTyping] = useState(false)

  useEffect(() => {
    if (loadingComplete) {
      config.debug && console.log('Delaying typing for 1 second...')
      setTimeout(() => {
        config.debug && console.log('Triggering typing...')
        setBeginTyping(true)
      }, 1100)
    }
  }, [loadingComplete])

  return (
    <MainContainer>
      <TextContainer>
        <Title>Hi, I&apos;m Marco Prouv&#201;</Title>
        <Subtitle>
          {beginTyping && (
            <Typewriter
              text={"I'm a full stack developer based out of Los Angeles"}
              delay={80}
              symbol={'_'}
            />
          )}
        </Subtitle>
      </TextContainer>
    </MainContainer>
  )
}

export default SceneOverlay
