import { useState } from "react"
import Context from "./context/context.js"

// Props:
// children --> (Child JSX Elements)
// eslint-disable-next-line react/prop-types
const SceneAnimationContextProvider = ({ children }) => {
  const [animateStory, setAnimateStory] = useState(false)
  const [animateAboutMe, setAnimateAboutMe] = useState(false)

  return (
    <Context.Provider
      value={{
        animateStory,
        setAnimateStory,
        animateAboutMe,
        setAnimateAboutMe
      }}>
      {children}
    </Context.Provider>
  )
}

export default SceneAnimationContextProvider
