import { useState, useEffect, useRef, useContext } from 'react'
import EasterEggContext from '../../../context-providers/easter-egg/context/context.js'
import ThemeContext from '../../../context-providers/theme/context/context.js'
import skills from './constants/skills.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  MainContainer,
  SectionContainer,
  TitleContainer,
  Title,
  ContentContainer,
  Categories,
  Category,
  CategoryName,
  SkillsContainer,
  SkillsTitle,
  Skills,
  Skill
} from './styled-components/skills.js'

const Story = () => {
  const { completedStep1, completedStep2, setCompletedStep2 } = useContext(EasterEggContext)
  const { lightTheme } = useContext(ThemeContext)
  const sectionRef = useRef(null)
  const skillsRef = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState('technical')
  const [scrolledOver, setScrolledOver] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const windowBottomScrollY = innerHeight + pageYOffset

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleSelectCategory = (e) => {
    setSelectedCategory(e.target.id)

    // if (window.innerWidth <= 900) {
    //   skillsRef?.current.scrollIntoView({ behavior: 'smooth' })
    // }
  }

  const handleClickKey = () => {
    if (!completedStep2) setCompletedStep2(true)
  }

  return (
    <MainContainer id="skills">
      <SectionContainer ref={sectionRef} lightTheme={lightTheme} scrolledOver={scrolledOver}>
        <TitleContainer>
          <Title>Skills</Title>
        </TitleContainer>

        <ContentContainer>
          <Categories>
            <Category
              id="technical"
              lightTheme={lightTheme}
              selected={selectedCategory === 'technical'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Technical
                <FontAwesomeIcon
                  icon={solid('laptop-code')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-2rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'technical' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>

            <Category
              id="languages"
              lightTheme={lightTheme}
              selected={selectedCategory === 'languages'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Languages
                <FontAwesomeIcon
                  icon={solid('code')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-2rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'languages' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>

            <Category
              id="apis"
              lightTheme={lightTheme}
              selected={selectedCategory === 'apis'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Third-Party APIs
                <FontAwesomeIcon
                  icon={solid('database')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-1.8rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'apis' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>

            <Category
              id="tools"
              lightTheme={lightTheme}
              selected={selectedCategory === 'tools'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Tools
                <FontAwesomeIcon
                  icon={solid('microchip')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-1.9rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'tools' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>

            <Category
              id="management"
              lightTheme={lightTheme}
              selected={selectedCategory === 'management'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Project Management
                <FontAwesomeIcon
                  icon={solid('code-branch')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-1.9rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'management' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>

            <Category
              id="artisanal"
              lightTheme={lightTheme}
              selected={selectedCategory === 'artisanal'}
              onClick={handleSelectCategory}>
              <CategoryName>
                Artisanal
                <FontAwesomeIcon
                  icon={solid('hammer')}
                  style={{
                    position: 'absolute',
                    top: '.2rem',
                    right: '-1.9rem',
                    fontSize: '1.1rem',
                    color: selectedCategory === 'artisanal' ? '#ff7777' : '#eee'
                  }}
                />
              </CategoryName>
            </Category>
          </Categories>

          <SkillsContainer ref={skillsRef}>
            <SkillsTitle>Skills</SkillsTitle>

            <Skills>
              {skills[selectedCategory].map((skill, i) => (
                <Skill key={`skill-${i}`}>{skill}</Skill>
              ))}

              {selectedCategory === 'tools' && completedStep1 && !lightTheme && (
                <Skill
                  completedStep2={completedStep2}
                  style={{
                    backgroundColor: '#26222a',
                    minWidth: '4rem',
                    borderColor: '#ffaa00',
                    color: '#00000000'
                  }}>
                  Key
                  <img
                    src="https://res.cloudinary.com/marco-prouve/image/upload/v1658279680/portfolio-site/easter-eggs/key_glbb9u.webp"
                    alt="Golden Key"
                    width="256"
                    height="102"
                    loading="lazy"
                    onClick={handleClickKey}
                  />
                </Skill>
              )}
            </Skills>
          </SkillsContainer>
        </ContentContainer>
      </SectionContainer>
    </MainContainer>
  )
}

export default Story
