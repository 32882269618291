const skills = {
  technical: [
    'React',
    'Next.js',
    'Express',
    'Node.js',
    'MongoDB',
    'Firebase',
    'PostgreSQL',
    'Three.js',
    'Redux',
    'Webhooks',
    'Material UI',
    'Ethereum DApps',
    'Parse Server',
    'Apache',
    'Virtualenv',
    'Systems Design',
    'Machine Learning',
    'TCP/IP'
  ],
  languages: [
    'JavaScript',
    'TypeScript',
    'HTML(5)',
    'CSS(3)',
    'Python',
    'C',
    'Java',
    'NoSQL',
    'SQL',
    'Bash',
    'PHP',
    'Swift 3 & 4'
  ],
  apis: [
    'AWS',
    'MongoDB',
    'Firebase',
    'Stripe',
    'IAPHub',
    'Twilio',
    'SendGrid',
    'Cloudinary',
    'SmartyStreets',
    'Google',
    'Bing',
    'Azure',
    'Ethereum'
  ],
  tools: [
    'VS Code',
    'Xcode',
    'Postman',
    'Swagger',
    'GIMP',
    'Adobe Photoshop',
    'Adobe Illustrator',
    'Discord',
    'Slack',
    'Firebug',
    'React Dev Tools',
    'Chrome Dev Tools',
    'Arduino',
    'Raspberry Pi',
    'Altium Deesigner',
    'Blender',
    'Mac',
    'Windows',
    'Linux'
  ],
  management: [
    'GitHub',
    'Heroku',
    'Jenkins',
    'Click Up',
    'Asana',
    'Jira',
    'Trello',
    'Google Drive',
    'Agile'
  ],
  artisanal: ['Woodworking', 'Metalsmithing', 'Welding (MIG)', 'Soldering']
}

export default skills
