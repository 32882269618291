// import { useState, useEffect, useRef } from "react"
import {
  MainContainer,
  Mouse,
  Dot,
  ScrollTextContainer,
  ScrollTextLetter
} from './styled-components/scroll-indicator.js'

// eslint-disable-next-line react/prop-types
const ScrollIndicator = ({ didScroll, color, style }) => {
  //   const [loaded, setLoaded] = useState(false)

  if (didScroll) return null

  return (
    <MainContainer style={style || {}}>
      <Mouse color={color}>
        <Dot color={color} />
      </Mouse>

      <ScrollTextContainer color={color}>
        <ScrollTextLetter>S</ScrollTextLetter>
        <ScrollTextLetter>C</ScrollTextLetter>
        <ScrollTextLetter>R</ScrollTextLetter>
        <ScrollTextLetter>O</ScrollTextLetter>
        <ScrollTextLetter>L</ScrollTextLetter>
        <ScrollTextLetter>L</ScrollTextLetter>
      </ScrollTextContainer>
    </MainContainer>
  )
}

export default ScrollIndicator
