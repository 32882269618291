const locations = [
  {
    id: 'los-angeles',
    location: 'Marina Del Rey, California',
    dates: '2020 - Present',
    desc: "I'm currently living in Marina Del Rey, CA, having moved here in 2020 during the pandemic. With the beach being so close, adventures at every corner, and sunshine year-round, I couldn't be happier being where I am."
  },
  {
    id: 'new-york',
    location: 'New York, New York',
    dates: '1997 - 2000 / 2002 - 2011 / 2019 - 2020',
    desc: 'New York is where I experienced most of my growing up.\n\nI first moved to New York at the age of 4 from Singapore, and then later at the age of 9 from Tokyo, at which point my family decided to settle in. After living in Chicago from 2017 - 2019, I decided to make one final move to NY in order to experience the city.'
  },
  {
    id: 'chicago',
    location: 'Chicago, Illinois',
    dates: '2017 - 2019',
    desc: 'After about a year of living in Madison, WI, I and my business partner at the time decided to make our way to Chicago in 2017. I spent a couple years in Chicago exploring the city and continuing my venture before moving to NY in 2019.\n\nIt was just before my move to New York that I decided to wrap up Kynect and begin my journey with CivilTalk.'
  },
  {
    id: 'wisconsin',
    location: 'Madison, Wisconsin',
    dates: '2011 - 2017',
    desc: 'In 2011, I started my studies at the University of Wisconsin - Madison. During that time I studied mechanical engineering and electrical engineering, but I really grew an interest for software, eventually graduating with a BS in Computer Science in 2016.\n\nFollowing graduation, I decided to start my first company, Kynect, and ended up staying in Madison, WI for another year in order to take advantage of university resources.'
  },
  {
    id: 'tokyo',
    location: 'Tokyo, Japan',
    dates: '2000 - 2002',
    desc: 'At the age of 7, I moved with my family to Tokyo, where I studied at the American School in Japan (ASIJ).\n\nI lived in Japan for about 2 1/2 years, after which my family decided it was time to return to New York.'
  },
  {
    id: 'singapore',
    location: 'Singapore, Singapore',
    dates: '1994 - 1997',
    desc: "At the age of 1, I moved with my family to Singapore. Understandably, I don't have many memories from my time there, but I do know how lucky I am to have lived in such a beautiful place with such amazing people.\n\nAfter about 3 years, we packed up and made our move to Scarsdale, New York."
  },
  {
    id: 'hong-kong',
    location: 'Hong Kong SAR',
    dates: '1993 - 1994',
    desc: "This is where it all began for me. Being born in Hong Kong is definitely a topic of conversation, but since I don't remember much of it the conversation kinda stops there...\n\nFast-forward a year and I found myself moving to Singapore for my next chapter."
  },
  {
    id: 'france',
    location: 'France',
    dates: '',
    desc: "France is so special to me. While I have never lived there for an extended period, I'm half french and my dad's side of the family resides there, so I have been regularly visiting the South of France during summers to spend time with family."
  },
  {
    id: 'honduras',
    location: 'Honduras',
    dates: '',
    desc: "I've never lived in Honduras nor do I get to visit often, but I am half Honduran from my mom's side. I am so lucky to have such amazing family out there and am so grateful every time I have an opportunity to travel there to see them."
  }
]

export default locations
