import { useContext } from 'react'
import ThemeContext from '../context-providers/theme/context/context.js'
import CTA from './components/cta/cta.js'
import AboutMe from './components/about-me/about-me.js'
import Story from './components/story/story.js'
import Skills from './components/skills/skills.js'
import Experience from './components/experience/experience.js'
import Interests from './components/interests/interests.js'
import ContactMe from './components/contact-me/contact-me.js'
// import SocialImg1200X630 from './components/social-img-1200-630/social-img-1200-630.js'
// import SocialImg1500X500 from './components/social-img-1500-500/social-img-1500-500.js'
import { MainContainer } from './styled-components/main.js'

// eslint-disable-next-line react/prop-types
const Main = ({ loadingComplete }) => {
  const { lightTheme } = useContext(ThemeContext)

  return (
    <MainContainer lightTheme={lightTheme}>
      <CTA loadingComplete={loadingComplete} />
      <AboutMe />
      <Story />
      <Skills />
      <Experience />
      <Interests />
      <ContactMe />
      {/* <SocialImg1200X630 /> */}
      {/* <SocialImg1500X500 /> */}
    </MainContainer>
  )
}

export default Main
