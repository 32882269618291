// import AvatarsAllScene from '../../../scenes/avatars-all-scene/avatars-all-scene.js'
// import allAvatarsImg from '../../../../media/background-images/all-avatars-2500-1500.png'
import SceneOverlay from './components/scene-overlay/scene-overlay.js'
import { MainContainer, SceneContainer, AllAvatarsBackground } from './styled-components/cta.js'

// eslint-disable-next-line react/prop-types
const CTA = ({ loadingComplete }) => {
  return (
    <MainContainer>
      <SceneContainer>
        <AllAvatarsBackground
          style={{
            backgroundImage: `url(https://res.cloudinary.com/marco-prouve/image/upload/v1658178519/portfolio-site/cta/all-avatars-2500-1500_aevdii.webp)`
          }}
        />
        {/* <AllAvatarsBackground style={{ backgroundImage: `url(${allAvatarsImg})` }} /> */}
        {/* <AvatarsAllScene /> */}
        <SceneOverlay loadingComplete={loadingComplete} />
      </SceneContainer>
    </MainContainer>
  )
}

export default CTA
