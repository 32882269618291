import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 2rem 2rem;
  position: relative;

  @media (max-width: 700px) {
    padding: 1.5rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 0rem 1.5rem;
  }
`

export const SceneContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  height: 100%;
  margin-right: 6%;
  border-radius: 1.5rem;
  position: relative;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 500px) {
    margin-top: -1.5rem;
  }
`

export const Popover = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? '#e6faffdd' : '#1D1A1Fee')};
  display: none;
  border-radius: 1.5rem;
  padding: 2rem;
  box-sizing: border-box;
  position: absolute;

  & .location-info {
    display: block;
    width: 100%;
    max-height: 19rem;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  & .location-info::-webkit-scrollbar {
    display: none;
  }

  & .location-info > div:not(:first-child) {
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
    width: 90%;
    padding: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const PopoverClose = styled.div`
  font-family: 'Bungee', cursive;
  font-size: 1.8rem;
  font-weight: regular;
  line-height: 1.9rem;
  color: ${({ lightTheme }) => (lightTheme ? '#000000' : '#ffffff')};
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
`

export const SectionTitle = styled.h1`
  display: block;
  text-align: center;
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: 100;
  line-height: 3.6rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};

  @media (max-width: 900px) {
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const SectionSubtitle = styled.h2`
  display: block;
  margin-top: 1rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};

  @media (max-width: 400px) {
    padding: 0 1rem;
  }
`

export const SectionContentContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;
  position: relative;

  @media (max-width: 900px) {
    margin-top: 0.5rem;
  }
`

export const SectionContent = styled.div`
  background: ${({ lightTheme }) =>
    lightTheme ? 'linear-gradient(#e6f9ff 30%, #ffffff 110%)' : '#1D1A1F'};
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  height: 24.6rem;
  padding: 2.5rem 2.5rem 4.5rem 2.5rem;
  margin-right: 4%;
  box-sizing: border-box;
  border-radius: 1.5rem;

  & .location-info {
    display: block;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  & .location-info::-webkit-scrollbar {
    display: none;
  }

  & .location-info > div:not(:first-child) {
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    display: none;
  }
`
