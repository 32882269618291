import { useState, useEffect, useRef } from 'react'
import config from '../../config.js'
import {
  MainContainer,
  RoundedSquare,
  LoaderContainer,
  SquaresContainer,
  LoadingTextContainer,
  LoadingTextLetter
} from './styled-components/loader.js'

// eslint-disable-next-line react/prop-types
const Loader = ({ setLoadingComplete }) => {
  const square1Ref = useRef(null)
  const square2Ref = useRef(null)
  const square3Ref = useRef(null)
  const square4Ref = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const handleLoad = () => {
      config.debug && console.log('Window loaded!')

      if (square1Ref.current) {
        square1Ref.current.style.animationIterationCount = '2'
        square1Ref.current.style.animationFillMode = 'forwards'
      }

      if (square2Ref.current) {
        square2Ref.current.style.animationIterationCount = '2'
        square2Ref.current.style.animationFillMode = 'forwards'
      }

      if (square3Ref.current) {
        square3Ref.current.style.animationIterationCount = '2'
        square3Ref.current.style.animationFillMode = 'forwards'
      }

      if (square4Ref.current) {
        square4Ref.current.style.animationIterationCount = '2'
        square4Ref.current.style.animationFillMode = 'forwards'
      }
    }

    if (document.readyState === 'complete') {
      handleLoad()
    } else {
      window.addEventListener('load', handleLoad)

      return () => {
        window.removeEventListener('load', handleLoad)
      }
    }
  }, [])

  const handleAnimationEnd = () => {
    setIsLoading(false)
    setLoadingComplete(true)
  }

  return (
    <MainContainer isLoading={isLoading}>
      <RoundedSquare isLoading={isLoading} />

      <LoaderContainer>
        <SquaresContainer isLoading={isLoading}>
          <div ref={square1Ref} />
          <div ref={square2Ref} />
          <div ref={square3Ref} />
          <div ref={square4Ref} onAnimationEnd={handleAnimationEnd} />
        </SquaresContainer>

        <LoadingTextContainer isLoading={isLoading}>
          <LoadingTextLetter>L</LoadingTextLetter>
          <LoadingTextLetter>O</LoadingTextLetter>
          <LoadingTextLetter>A</LoadingTextLetter>
          <LoadingTextLetter>D</LoadingTextLetter>
          <LoadingTextLetter>I</LoadingTextLetter>
          <LoadingTextLetter>N</LoadingTextLetter>
          <LoadingTextLetter>G</LoadingTextLetter>
          <LoadingTextLetter>.</LoadingTextLetter>
          <LoadingTextLetter>.</LoadingTextLetter>
          <LoadingTextLetter>.</LoadingTextLetter>
        </LoadingTextContainer>
      </LoaderContainer>
    </MainContainer>
  )
}

export default Loader
