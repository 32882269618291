import { createContext } from 'react'

// Define Context variable to be able to pass props down the entire tree of child components
const Context = createContext({
  completedStep1: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompletedStep1: () => {},
  completedStep2: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompletedStep2: () => {},
  completedStep3: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompletedStep3: () => {},
  completedStep4: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompletedStep4: () => {},
  completedStep5: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompletedStep5: () => {},
  numDaysCharged: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNumDaysCharged: () => {},
  showChargedEgg: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowChargedEgg: () => {}
})

export default Context
