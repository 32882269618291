/* eslint-disable no-undef */
// SETUP ENVIRONMENT VARIABLE
let env

if (
  process.env.REACT_APP_NODE_ENV === 'local' ||
  process.env.REACT_APP_NODE_ENV === 'undefined' ||
  typeof process.env.REACT_APP_NODE_ENV === 'undefined'
) {
  env = 'local'
} else {
  env = 'production'
}

// ****************************************
// PRODUCTION *****************************
// ****************************************
const production = {
  debug: false
}

// ****************************************
// LOCAL **********************************
// ****************************************
const local = {
  debug: true
}

const config = {
  production,
  local
}

export default config[env]
