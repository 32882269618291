import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 2rem 2rem;

  @media (max-width: 700px) {
    padding: 2rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 1rem 1.5rem;
  }
`

export const SectionContainer = styled.div`
  background: linear-gradient(#80dfff 0%, #00bfff 70%);
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  box-sizing: border-box;
  ${'' /* border-bottom: 1px solid #fff; */}
  border-radius: 1.5rem;
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 901px) {
    & > svg {
      display: none;
    }
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  display: inline-block;
  width: 60%;
  padding: 4rem 0 4rem 4rem;
  vertical-align: top;
  box-sizing: border-box;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  position: relative;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    padding: 2.5rem 2.5rem 0.5rem 2.5rem;
    border-radius: 1.5rem;
  }
`

export const Title = styled.p`
  display: block;
  ${'' /* padding-bottom: .5rem; */}
  ${'' /* border-bottom: 4px solid #ff7777; */}
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: 100;
  line-height: 3.6rem;
  color: #fff;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const MainTextContainer = styled.div`
  display: block;
  max-height: 12.9rem;
  ${'' /* max-height: 15.9rem; */}
  margin-top: 1.5rem;
  ${'' /* padding-right: 24px; */}
  position: relative;
`

export const MainText = styled.div`
  display: block;
  max-height: 12.9rem;
  ${'' /* max-height: 15.9rem; */}
  text-align: left;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.35rem;
  color: #fff;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const SceneContainer = styled.div`
  display: inline-block;
  width: 40%;
  height: 100%;
  min-height: 29rem;
  vertical-align: top;
  overflow: hidden;
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    min-height: 23rem;
    border-radius: 1.5rem;

    & > svg {
      display: none;
    }
  }
`
