import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? '#26222a' : '#1D1A1F')};
`

export const InnerContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 4rem 0;
  box-sizing: border-box;
  color: white;
`

export const SquaresOuterContainer = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`

export const SquaresInnerContainer = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: relative;

  & > div {
    display: block;
    width: 20px;
    height: 20px;
    border: 6px solid #ff6a6a;
    border-radius: 0.2rem;
    position: absolute;
  }

  & > div:nth-child(1) {
    border-color: #02bfff;
    top: 0;
    left: 0;
  }

  & > div:nth-child(2) {
    border-color: #ff6a6a;
    top: 0;
    right: 0;
  }

  & > div:nth-child(3) {
    border-color: #02bfff;
    bottom: 0;
    left: 0;
  }

  & > div:nth-child(4) {
    border-color: #02bfff;
    bottom: 0;
    right: 0;
  }
`

export const LogoContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 1.8rem;
  padding: 0 3rem;
  text-align: center;
`

export const Logo = styled.div`
  display: inline-block;
  font-family: 'Bungee', cursive;
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 100;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 600px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`

export const Heading = styled.p`
  display: block;
  margin-top: 0.5rem;
  padding: 0 3rem;
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #cccccc;

  @media (max-width: 600px) {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`

export const SocialLinksContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0 2rem;
  text-align: center;

  @media (max-width: 600px) {
    margin-top: 1.2rem;
  }
`

export const SocialLinkContainer = styled.div`
  display: inline-block;
  height: 3rem;
  width: 3rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #00000055;
  }

  @media (max-width: 600px) {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.3rem 0.1rem 0 0.1rem;
  }
`

export const SocialLink = styled.img`
  display: block;
  width: auto;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    height: 1.2rem;
  }
`
