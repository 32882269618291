import { useState } from 'react'
import Context from './context/context.js'

// Props:
// children --> (Child JSX Elements)
// eslint-disable-next-line react/prop-types
const ThemeContextProvider = ({ children }) => {
  const [lightTheme, setLightTheme] = useState(true)

  return <Context.Provider value={{ lightTheme, setLightTheme }}>{children}</Context.Provider>
}

export default ThemeContextProvider
