import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 2rem 2rem;

  @media (max-width: 900px) {
    padding: 2rem 1.5rem 1rem 1.5rem;
  }

  @media (max-width: 700px) {
    padding: 2rem 1.5rem 1rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 1rem 1.5rem;
  }
`

export const SectionContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  box-sizing: border-box;
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;
  position: relative;

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const SectionTitle = styled.h1`
  display: block;
  padding: 0 4rem;
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: 100;
  line-height: 3.6rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;

  @media (max-width: 900px) {
    padding: 0 1.5rem;
    font-size: 3rem;
    line-height: 3rem;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }

  @media (max-width: 500px) {
    padding: 0;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const SectionSubtitle = styled.h2`
  display: block;
  width: 100%;
  padding: 0 4.2rem;
  margin-top: 1rem;
  box-sizing: border-box;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;

  @media (max-width: 900px) {
    padding: 0 1.5rem;
    text-align: center;
  }

  @media (max-width: 400px) {
    padding: 0 1rem;
  }
`

export const ProjectsContainer = styled.div`
  display: block;
  width: 100%;
  ${'' /* margin-top: 4rem; */}
  padding: 4rem 4rem 0 4rem;
  box-sizing: border-box;
  border-radius: 1.5rem;

  @media (max-width: 900px) {
    padding: 3.5rem 2.5rem 2.5rem 2.5rem;
  }

  @media (max-width: 500px) {
    padding: 2.5rem 2rem 2.5rem 2rem;
  }
`

export const Carousel = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
`

export const Slider = styled.div`
  display: flex;
  width: ${({ count }) => `${count}00%`};
  flex-shrink: 0;
  transition: all 0.8s ease-in-out;

  & > div {
    width: ${({ count }) => `${100 / count}%`};
    flex-basis: ${({ count }) => `${100 / count}%`};
  }
`

export const SliderItem = styled.div`
  display: flex;
  flex-shrink: 0;
  flex: 1;
  align-items: center;
`

export const Project = styled.div`
  display: block;
  width: 100%;
  padding: 0 2px 0 2px;
`

export const ProjectLogoContainer = styled.div`
  background: ${({ color }) => `linear-gradient(135deg, ${color}, ${color}aa)`};
  display: block;
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  position: relative;

  @media (max-width: 900px) {
    width: 5.5rem;
    height: 5.5rem;
  }

  @media (max-width: 700px) {
    width: 5rem;
    height: 5rem;
  }
`

export const ProjectLogo = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 55%;
  height: 55%;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`

export const ProjectName = styled.p`
  background-color: ${({ color }) => color};
  background: ${({ color }) => `linear-gradient(90deg, ${color} 0%, ${color}aa 50%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin: 1.9rem 0 0 -2px;
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 2.6rem;
  font-weight: 100;
  line-height: 2.6rem;
  color: #333;
  word-break: break-word;
  white-space: pre-line;

  @media (max-width: 900px) {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
    line-height: 2rem;
  }

  @media (max-width: 500px) {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
`

export const ProjectDates = styled.p`
  background-color: ${({ color }) => color};
  background: ${({ color }) => `linear-gradient(90deg, ${color} 0%, ${color}aa 50%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-top: 0.5rem;
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 1.1rem;
  color: #333;
  word-break: break-word;
  white-space: pre-line;
`

export const ProjectDesc = styled.p`
  display: block;
  max-height: 13.5rem;
  margin-top: 1.6rem;
  text-align: left;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 900px) {
    margin-top: 1.8rem;
    max-height: 15rem;
  }
`

export const Links = styled.div`
  display: block;
  width: 100%;
  margin-top: 1.6rem;
  padding-bottom: 0.3rem;

  & > a:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const Link = styled.div`
  background: linear-gradient(#f8f8f8 0%, #f0f0f0 70%);
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-top: 0.25rem;
  vertical-align: top;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;

  &:hover {
    background: ${({ color }) => `linear-gradient(90deg, ${color} 0%, ${color}aa 70%)`};
    width: auto;
    box-shadow: ${({ lightTheme, color }) =>
      lightTheme ? '0px 2px 5px 0px ${color}33' : `0px 2px 5px 0px ${color}33`};

    & > span {
      width: ${({ hoverWidth }) => hoverWidth};
    }
  }
`

export const LinkIcon = styled.img`
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: 50%;
  left: 0.9rem;
  transform: translate(0, -50%);
`

export const LinkText = styled.span`
  display: block;
  width: 0;
  margin: 1rem 1rem 0 2.9rem;
  /* overflow: hidden; */
  white-space: nowrap;
  word-wrap: nowrap;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
  color: #fff;
  transition: all 0.3s ease-in-out;
`
