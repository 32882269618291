import { useState, useEffect, useRef } from 'react'
import { Button } from '@mui/material'
import {
  MainContainer,
  SectionContainer,
  Title,
  Subtitle,
  ButtonsContainer
} from './styled-components/contact-me.js'

const styles = {
  email: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '18rem',
    height: '3.5rem',
    marginRight: '2rem',
    fontFamily: 'Bungee, cursive',
    fontSize: '.9rem',
    fontWeight: 'regular',
    color: '#fff',
    border: '3px solid #fff',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#ff6666',
      border: '3px solid #fff'
    },
    '@media(max-width: 900px)': {
      display: 'block',
      margin: '0 auto'
    },
    '@media(max-width: 600px)': {
      width: '100%'
    },
    '@media(max-width: 400px)': {
      fontSize: '.85rem'
    }
  },
  resume: {
    backgroundColor: '#fff',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '18rem',
    height: '3.5rem',
    fontFamily: 'Bungee, cursive',
    fontSize: '.9rem',
    fontWeight: 'regular',
    color: '#ff6666',
    border: '3px solid #fff',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    '&:hover': {
      backgroundColor: 'none',
      color: '#fff',
      border: '3px solid #fff'
    },
    '@media(max-width: 900px)': {
      display: 'block',
      margin: '1rem auto 0 auto'
    },
    '@media(max-width: 600px)': {
      width: '100%'
    },
    '@media(max-width: 400px)': {
      fontSize: '.85rem'
    }
  }
}

const ContactMe = () => {
  const sectionRef = useRef(null)
  const [scrolledOver, setScrolledOver] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const windowBottomScrollY = innerHeight + pageYOffset

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    window.addEventListener('scroll', handleScroll, {passive: true})

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <MainContainer id="contact-me">
      <SectionContainer ref={sectionRef} scrolledOver={scrolledOver}>
        <Title>Contact Me</Title>
        <Subtitle>
          If you are looking for someone to fill an open full-stack position or would simply like to
          consult me on full-stack engineering decisions, please do not hesitate to contact me.
        </Subtitle>

        <ButtonsContainer>
          <a href="mailto:me@marcoprouve.com" target="_blank" rel="noreferrer" aria-label="Email">
            <Button variant="outlined" sx={styles.email}>
              me@marcoprouve.com
            </Button>
          </a>

          <a href="/resume.pdf" target="_blank" rel="noreferrer" aria-label="Resume">
            <Button variant="outlined" sx={styles.resume}>
              Resume
            </Button>
          </a>
        </ButtonsContainer>
      </SectionContainer>
    </MainContainer>
  )
}

export default ContactMe
