import { useContext } from 'react'
import ThemeContext from '../context-providers/theme/context/context.js'
import {
  MainContainer,
  InnerContainer,
  SquaresOuterContainer,
  SquaresInnerContainer,
  LogoContainer,
  Logo,
  Heading,
  SocialLinksContainer,
  SocialLinkContainer,
  SocialLink
} from './styled-components/footer.js'

const Footer = () => {
  const { lightTheme } = useContext(ThemeContext)

  return (
    <MainContainer lightTheme={lightTheme}>
      <InnerContainer>
        <SquaresOuterContainer>
          <a href="#" aria-label="Home">
            <SquaresInnerContainer>
              <div />
              <div />
              <div />
              <div />
            </SquaresInnerContainer>
          </a>
        </SquaresOuterContainer>

        <LogoContainer>
          <a href="#" aria-label="Marco Prouve">
            <Logo>Marco Prouv&#201;</Logo>
          </a>
        </LogoContainer>

        <Heading>Thank you for reading, I hope to hear from you!</Heading>

        <SocialLinksContainer>
          <a href="mailto:me@marcoprouve.com" target="_blank" rel="noreferrer" aria-label="Email">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/email-white_rwd6nw_dszxvd.webp"
                alt="Email Icon"
                width="512"
                height="512"
                loading="lazy"
              />
            </SocialLinkContainer>
          </a>

          <a
            href="https://www.instagram.com/marcoprouve/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/instagram-white_asbtvm_ir7oph.webp"
                alt="Instagram Icon"
                width="512"
                height="512"
                loading="lazy"
              />
            </SocialLinkContainer>
          </a>

          <a
            href="https://twitter.com/marco_prouve"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/twitter-white_lyul82_jujymk.webp"
                alt="Twitter Icon"
                width="512"
                height="512"
                loading="lazy"
              />
            </SocialLinkContainer>
          </a>

          <a
            href="https://www.linkedin.com/in/marcoprouve/"
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/linkedin-white_gyzvhn_sodklb.webp"
                alt="LinkedIn Icon"
                width="512"
                height="512"
                loading="lazy"
                style={{ marginBottom: '.3rem' }}
              />
            </SocialLinkContainer>
          </a>

          <a href="https://github.com/mprouve" target="_blank" rel="noreferrer" aria-label="GitHub">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/github-white_l9df4a_bfdtwp.webp"
                alt="GitHub Icon"
                width="512"
                height="512"
                loading="lazy"
              />
            </SocialLinkContainer>
          </a>

          <a
            href="https://discordapp.com/users/328009884660662272/"
            target="_blank"
            rel="noreferrer"
            aria-label="Discord">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/discord-white_n9msvy_fuassb.webp"
                alt="Discord Icon"
                width="512"
                height="512"
                loading="lazy"
                style={{ marginBottom: '.1rem' }}
              />
            </SocialLinkContainer>
          </a>

          <a
            href="https://www.reddit.com/user/marcoprouve"
            target="_blank"
            rel="noreferrer"
            aria-label="Reddit">
            <SocialLinkContainer>
              <SocialLink
                src="https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/reddit-white_ynyptp_wu8jxd.webp"
                alt="Reddit Icon"
                width="512"
                height="512"
                loading="lazy"
                style={{ marginBottom: '.3rem' }}
              />
            </SocialLinkContainer>
          </a>
        </SocialLinksContainer>
      </InnerContainer>
    </MainContainer>
  )
}

export default Footer
