import styled, { keyframes } from 'styled-components'

export const MainContainer = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? 'white' : '#26222a')};
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: top 0.3s ease-in-out;
`

export const InnerContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 1.9rem 6rem 1.6rem 6rem;
  box-sizing: border-box;
  line-height: 2.4rem;
  position: relative;

  @media (max-width: 900px) {
    padding: 1.9rem 4rem 1.6rem 4rem;
  }

  @media (max-width: 700px) {
    padding: 1.5rem 2.5rem 1.2rem 2.5rem;
  }

  @media (max-width: 500px) {
    padding: 1.5rem 2rem 1.2rem 2rem;
  }
`

const letterAnimation = () => {
  return keyframes`
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: translateY(.2rem) scale(1, .6);
    }
    70% {
      transform: translateY(0) scale(1);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  `
}

const accentAnimation = () => {
  return keyframes`
    0% {
      bottom: 6rem;
    }
    20% {
      bottom: .1rem;
    }
    40% {
      bottom: -.1rem;
    }
    70% {
      bottom: 0.1rem;
    }
    100% {
      bottom: 0.1rem;
    }
  `
}

export const Logo = styled.span`
  display: inline-block;
  font-family: 'Bungee', cursive;
  cursor: pointer;

  ${
    '' /* background-color: red; 
  background-image: linear-gradient(45deg, #b30059 50%, #ffe100 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent; */
  }

  & span {
    display: inline-block;
    vertical-align: top;
    font-size: 1.9rem;
    line-height: 2.5rem;
    color: ${({ lightTheme }) => (lightTheme ? '#000000' : '#ffffff')};

    @media (max-width: 500px) {
      font-size: 1.6rem;
    }
  }

  & > #letter-e {
    position: relative;
    animation-name: ${({ hovered }) => (hovered ? letterAnimation() : 'none')};
    animation-duration: 1s;
  }

  & > #letter-e > span {
    position: absolute;
    bottom: 6rem;
    left: 50%;
    transform: translate(-50%, 0);
    animation-name: ${({ hovered }) => (hovered ? accentAnimation() : 'none')};
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
`

const menuContainerAnimationHover = (end) => {
  return keyframes`
    0% {
      transform: translate(0, -50%) rotate( 0deg );
    }
    100% {
      transform: translate(0, -50%) rotate( 180deg );
      width: 2.6rem;
      height: 2.6rem;
      right: ${end.right};
    }
  `
}

const menuContainerAnimationActive = (start, end) => {
  return keyframes`
    0% {
      transform: translate(0, -50%) rotate( 180deg );
      width: 2.6rem;
      height: 2.6rem;
      right: ${start.right};
    }
    100% {
      transform: translate(0, -50%) rotate( 180deg );
      width: 2.4rem;
      height: 2.4rem;
      right: ${end.right};
    }
  `
}

export const MenuContainer = styled.div`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 6rem;
  transform: translate(0, -50%);
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out, right 0.1s ease-in-out;

  & > div {
    display: block;
    width: ${({ lightTheme }) => (lightTheme ? '17px' : '16px')};
    height: ${({ lightTheme }) => (lightTheme ? '17px' : '16px')};
    border: ${({ lightTheme }) => (lightTheme ? '5px solid #000000' : '4px solid #ffffff')};
    border-radius: 0.2rem;
    position: absolute;
  }

  & > div:nth-child(1) {
    ${'' /* border-color: #000; */}
    top: 0;
    left: 0;
  }

  & > div:nth-child(2) {
    ${'' /* border-color: #000; */}
    top: 0;
    right: 0;
  }

  & > div:nth-child(3) {
    ${'' /* border-color: #000; */}
    bottom: 0;
    left: 0;
  }

  & > div:nth-child(4) {
    ${'' /* border-color: #000; */}
    bottom: 0;
    right: 0;
  }

  &:hover {
    animation-name: ${menuContainerAnimationHover({ right: '5.9rem' })};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:active {
    animation-name: ${menuContainerAnimationActive({ right: '5.9rem' }, { right: '6rem' })};
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
  }

  @media (max-width: 900px) {
    right: 4rem;

    &:hover {
      animation-name: ${menuContainerAnimationHover({ right: '3.9rem' })};
    }
    &:active {
      animation-name: ${menuContainerAnimationActive({ right: '3.9rem' }, { right: '4rem' })};
    }
  }

  @media (max-width: 700px) {
    right: 2.5rem;

    &:hover {
      animation-name: ${menuContainerAnimationHover({ right: '2.4rem' })};
    }
    &:active {
      animation-name: ${menuContainerAnimationActive({ right: '2.4rem' }, { right: '2.5rem' })};
    }
  }

  @media (max-width: 500px) {
    right: 2rem;

    &:hover {
      animation-name: ${menuContainerAnimationHover({ right: '1.9rem' })};
    }
    &:active {
      animation-name: ${menuContainerAnimationActive({ right: '1.9rem' }, { right: '2rem' })};
    }
  }
`

export const ClickAwaySection = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 5.8rem;
  bottom: 0;
  left: ${({ open }) => (open ? '0' : '100%')};
  z-index: -2;

  @media (max-width: 700px) {
    top: 5rem;
  }
`
