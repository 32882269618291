import styled, { keyframes } from 'styled-components'

const dotAnimation = () => {
  return keyframes`
    0% {
      top: .3rem;
      opacity: 1;
    }
    30% {
      top: .7rem;
      opacity: 1;
    }
    40% {
      top: .7rem;
      opacity: 1;
    }
    60% {
      top: .7rem;
      opacity: 0;
    }
    75% {
      top: .3rem;
      opacity: 0;
    }
    100% {
      top: .3rem;
      opacity: 1;
    }
  `
}

export const MainContainer = styled.div`
  display: block;
  margin-top: 1.5rem;
  width: 100%;
`

export const Mouse = styled.div`
  background-color: ${({ color }) => `${color || '#ffffff'}33`};
  display: inline-block;
  width: 1rem;
  height: 1.5rem;
  vertical-align: middle;
  border: ${({ color }) => `3px solid ${color || '#fff'}`};
  border-radius: 1rem;
  box-shadow: ${({ color }) => `0 0 2px 5px ${color || '#ffffff'}33`};
  position: relative;

  & > div {
    animation: ${dotAnimation()} 3s infinite;
  }
`

export const Dot = styled.div`
  background-color: ${({ color }) => `${color || '#ffffff'}`};
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  top: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
`

const LetterSAnimation = () => {
  return keyframes`
    0% {
      transform: translate(0,0) scale(1, 1);
    }
    3% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    6% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

const LetterCAnimation = () => {
  return keyframes`
    3% {
      transform: translate(0,0) scale(1, 1);
    }
    6% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    9% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

const LetterRAnimation = () => {
  return keyframes`
    6% {
      transform: translate(0,0) scale(1, 1);
    }
    9% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    12% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

const LetterOAnimation = () => {
  return keyframes`
    9% {
      transform: translate(0,0) scale(1, 1);
    }
    12% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    15% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

const LetterL1Animation = () => {
  return keyframes`
    12% {
      transform: translate(0,0) scale(1, 1);
    }
    15% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    18% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

const LetterL2Animation = () => {
  return keyframes`
    15% {
      transform: translate(0,0) scale(1, 1);
    }
    18% {
      transform: translate(0,-.2rem) scale(1, 1.2);
    }
    21% {
      transform: translate(0, 0) scale(1, 1);
    }
  `
}

export const ScrollTextContainer = styled.div`
  display: inline-block;
  margin: 0.1rem 0 0 0.7rem;
  vertical-align: middle;
  text-align: left;

  & > span {
    color: ${({ color }) => `${color || '#ffffff'}`};
  }

  & > span:nth-child(1) {
    animation: ${LetterSAnimation()} 3s infinite;
  }

  & > span:nth-child(2) {
    animation: ${LetterCAnimation()} 3s infinite;
  }

  & > span:nth-child(3) {
    animation: ${LetterRAnimation()} 3s infinite;
  }

  & > span:nth-child(4) {
    animation: ${LetterOAnimation()} 3s infinite;
  }

  & > span:nth-child(5) {
    animation: ${LetterL1Animation()} 3s infinite;
  }

  & > span:nth-child(6) {
    animation: ${LetterL2Animation()} 3s infinite;
  }
`

export const ScrollTextLetter = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 0.9rem;
`
