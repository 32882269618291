import { useState } from 'react'
import Context from './context/context.js'

// Props:
// children --> (Child JSX Elements)
// eslint-disable-next-line react/prop-types
const EasterEggContextProvider = ({ children }) => {
  const [completedStep1, setCompletedStep1] = useState(false)
  const [completedStep2, setCompletedStep2] = useState(false)
  const [completedStep3, setCompletedStep3] = useState(false)
  const [completedStep4, setCompletedStep4] = useState(false)
  const [completedStep5, setCompletedStep5] = useState(false)
  const [numDaysCharged, setNumDaysCharged] = useState(0)
  const [showChargedEgg, setShowChargedEgg] = useState(true)

  return (
    <Context.Provider
      value={{
        completedStep1,
        setCompletedStep1,
        completedStep2,
        setCompletedStep2,
        completedStep3,
        setCompletedStep3,
        completedStep4,
        setCompletedStep4,
        completedStep5,
        setCompletedStep5,
        numDaysCharged,
        setNumDaysCharged,
        showChargedEgg,
        setShowChargedEgg
      }}>
      {children}
    </Context.Provider>
  )
}

export default EasterEggContextProvider
