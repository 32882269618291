import { useState, useEffect, useRef, useContext } from 'react'
import SceneAnimation from '../../../context-providers/scene-animation/context/context.js'
import ThemeContext from '../../../context-providers/theme/context/context.js'
import EarthScene from '../../../scenes/earth-scene/earth-scene.js'
import ScrollIndicator from '../../../reuseable/scroll-indicator/scroll-indicator.js'
import LocationInfo from './components/location-info/location-info.js'
import {
  MainContainer,
  SceneContainer,
  Popover,
  PopoverClose,
  SectionTitle,
  SectionSubtitle,
  SectionContentContainer,
  SectionContent
} from './styled-components/story.js'

const Story = () => {
  const { lightTheme } = useContext(ThemeContext)
  const { animateStory, setAnimateStory } = useContext(SceneAnimation)
  const sectionRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [activeMarker, setActiveMarker] = useState('los-angeles')
  const [scrolledOver, setScrolledOver] = useState(false)
  const [scaleDownScene, setScaleDownScene] = useState(false)
  const [enableAnimation, setEnableAnimation] = useState(false)
  const activeMarkerRef = useRef('los-angeles')

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const { top, bottom } = sectionRef.current.getBoundingClientRect()
        const windowBottomScrollY = pageYOffset + innerHeight
        const isTopVisible = top > 0 && top < innerHeight
        const isBottomVisible = bottom > 0 && bottom < innerHeight

        // Set Scene animations of in focus or not
        if (isTopVisible || isBottomVisible) {
          if (!enableAnimation) {
            // console.log('ANIMATE - Story')
            setEnableAnimation(true)
          }
        } else {
          if (enableAnimation) {
            // console.log("DON'T ANIMATE - Story")
            setEnableAnimation(false)
          }
        }

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    const handleResize = () => {
      if (window.innerWidth > 900) {
        if (!activeMarkerRef.current) {
          setActiveMarker('los-angeles')
        }
      }
    }

    if (window.innerWidth <= 900 && !loaded) {
      setActiveMarker('')
    }

    if (window.innerWidth <= 500 && !loaded) {
      setScaleDownScene(true)
    }

    if (!loaded) setLoaded(true)

    window.addEventListener('scroll', handleScroll, { passive: true })
    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [enableAnimation])

  useEffect(() => {
    if (animateStory !== enableAnimation) {
      setAnimateStory(enableAnimation)
    }
  }, [enableAnimation])

  useEffect(() => {
    activeMarkerRef.current = activeMarker
  }, [activeMarker])

  return (
    <MainContainer id="story">
      <SectionTitle lightTheme={lightTheme}>Story</SectionTitle>
      <SectionSubtitle lightTheme={lightTheme}>
        Interact with the map below to uncover my story
      </SectionSubtitle>

      <SectionContentContainer ref={sectionRef} scrolledOver={scrolledOver}>
        <SceneContainer>
          <EarthScene
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            scaleDownScene={scaleDownScene}
          />
        </SceneContainer>

        <SectionContent lightTheme={lightTheme}>
          <LocationInfo activeMarker={activeMarker} />
          <ScrollIndicator color={'#ff7777'} style={{ padding: '0 0 5px 5px' }} />
        </SectionContent>

        <Popover lightTheme={lightTheme} open={activeMarker !== ''}>
          <PopoverClose lightTheme={lightTheme} onClick={() => setActiveMarker('')}>
            X
          </PopoverClose>
          <LocationInfo activeMarker={activeMarker} />
          <ScrollIndicator color={'#ff7777'} style={{ padding: '0 0 5px 5px' }} />
        </Popover>
      </SectionContentContainer>
    </MainContainer>
  )
}

export default Story
