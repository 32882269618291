import { useState, useEffect, useRef, useContext } from 'react'
import ThemeContext from '../context-providers/theme/context/context.js'
import SideNav from './components/side-nav/side-nav.js'
import {
  MainContainer,
  InnerContainer,
  Logo,
  MenuContainer,
  ClickAwaySection
  // NavigationLinksContainer,
  // NavigationLink
} from './styled-components/header.js'

const Header = () => {
  const { lightTheme } = useContext(ThemeContext)
  const ref = useRef(null)
  const pastScrollY = useRef(0)
  const [sideNav, setSideNav] = useState(false)
  const [logoHovered, setLogoHovered] = useState(false)
  const logoName = 'Marco Prouve'
  const logoCharSpans = logoName.split('').map((char, i) => {
    if (char === 'o' && i === 4) {
      return <span key={i}>{char}&nbsp;</span>
    } else if (char === ' ') {
      return null
    } else if (char === 'e') {
      return (
        <span key={i} id="letter-e">
          {char}
          <span key={-i}>&#180;</span>
        </span>
      )
    } else {
      return <span key={-i}>{char}</span>
    }
  })

  useEffect(() => {
    const handleScroll = () => {
      if (ref?.current) {
        const currentScrollY = window.scrollY

        if (currentScrollY <= 16) {
          ref.current.style.top = '0'
        } else {
          if (pastScrollY.current < currentScrollY) {
            ref.current.style.top = '-7.5rem'

            if (sideNav) {
              setSideNav(false)
            }
          } else {
            ref.current.style.top = '0'
          }

          pastScrollY.current = currentScrollY
        }
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sideNav])

  return (
    <MainContainer ref={ref} lightTheme={lightTheme}>
      <InnerContainer>
        <SideNav open={sideNav} setOpen={setSideNav} />

        <a href="#" aria-label="Marco Prouve">
          <Logo
            lightTheme={lightTheme}
            hovered={logoHovered}
            onMouseOver={() => setLogoHovered(true)}
            onClick={() => setSideNav(false)}>
            {logoCharSpans}
          </Logo>
        </a>

        <MenuContainer lightTheme={lightTheme} onClick={() => setSideNav(!sideNav)}>
          <div />
          <div />
          <div />
          <div />
        </MenuContainer>

        <ClickAwaySection open={sideNav} onClick={() => setSideNav(false)} />
      </InnerContainer>
    </MainContainer>
  )
}

export default Header
