import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const AnimatedSymbol = styled.span`
  display: inline-block;
  margin-left: .1rem;
  animation: ${animation} 1s infinite;
`

// eslint-disable-next-line react/prop-types
const Typewriter = ({ text, symbol, delay, style }) => {
  const [outputText, setOutputText] = useState('')
  // eslint-disable-next-line react/prop-types
  const outputTextArr = text.split('')

  useEffect(() => {
    let output = ''
    let index = 0

    const interval = setInterval(() => {
      output += outputTextArr[index]

      setOutputText(output)

      if (index === outputTextArr.length - 1) {
        clearInterval(interval)
      }

      index += 1
    }, delay)

    return () => {
      clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [])

  return (
    <p style={style || {}}>
      {outputText}
      <AnimatedSymbol>{symbol}</AnimatedSymbol>
    </p>
  )
}

export default Typewriter
