const style =
  'color: #ff7777; font-size: 14px; font-weight: bold; font-family: monospace; text-shadow: 2px 2px 0 #ff777733;'
// const style = 'color: #ffffff; font-size: 14px; font-weight: bold; font-family: monospace;'

const easterEggs = {
  start: [
    {
      log: '%c\n          ████████\n        ██        ██\n      ██▒▒▒▒        ██\n    ██▒▒▒▒▒▒      ▒▒▒▒██\n    ██▒▒▒▒▒▒      ▒▒▒▒██\n  ██  ▒▒▒▒        ▒▒▒▒▒▒██\n  ██                ▒▒▒▒██\n██▒▒      ▒▒▒▒▒▒          ██\n██      ▒▒▒▒▒▒▒▒▒▒        ██\n██      ▒▒▒▒▒▒▒▒▒▒    ▒▒▒▒██\n██▒▒▒▒  ▒▒▒▒▒▒▒▒▒▒  ▒▒▒▒▒▒██\n  ██▒▒▒▒  ▒▒▒▒▒▒    ▒▒▒▒██\n  ██▒▒▒▒            ▒▒▒▒██\n    ██▒▒              ██\n      ████        ████\n          ████████\n\n... Can you uncover the secrets hidden within?',
      style
    }
  ],
  egg1: [
    // {
    //   log: '%c ',
    //   style:
    //     'font-size: 1px; padding: 50px 150px; background-size: 300px 100px; background: url(https://res.cloudinary.com/marco-prouve/image/upload/v1658257625/portfolio-site/social-media-crawler/social-meta-1500-500_dcmcj0.png) no-repeat;'
    // },
    {
      log: "%cIf you made it here, maybe you'd be willing to explore a bit further...\n\nIt's said that light can expose the secrets concealed by the darkness, but I like to think that the opposite can be true as well.\n\nDon't you?\n ",
      style
    }
  ],
  egg2: [
    {
      log: '%cI\'ve heard stories of this key!\n\nThey say it belongs to a treasure that has been lost in time...\n\nLooks like there\'s a small engraving on it. It reads...\n\n"A past without a past"\n\nI wonder what that could mean?\n ',
      style
    }
  ],
  egg3: [
    {
      log: "%cCould it really be? The chest... you've found it!\n\nIt seems you might actually be able to discover the treasure this chest has been protecting for so long...\n ",
      style
    }
  ],
  egg4: [
    {
      log: "%cAmazing! Can you believe this treasured egg has been kept hidden for so long?\n\nBut something seems to be a bit off, don't you think?\n\nThe egg was said to have a radiant glow, but it looks a bit dim...\n\nThe darkness must have drained it of its light after all this time. Maybe we should let it rest in the light for a few days, what do you think?",
      style
    }
  ],
  complete: [
    {
      log: "%cIT WORKED! The egg has been restored, and you've successfully uncovered the treasure thats been hidden for so long...\n\nI can't thank you enough for what you've done, I hope you enjoyed exploring as much as I did.\n\nI'm sure there are more treasures like this that are yet to be discovered, so keep pushing past where most stop.\n\n👾👾👾",
      style
    }
  ]
}

export default easterEggs
