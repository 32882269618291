import { useContext } from 'react'
import EasterEggContext from '../context-providers/easter-egg/context/context.js'
import { MainContainer, EggContainer, RaysLeft, RaysRight } from './styled-components/easter-egg.js'

// eslint-disable-next-line react/prop-types
const EasterEgg = () => {
  const { completedStep5, showChargedEgg } = useContext(EasterEggContext)

  return (
    <MainContainer completedStep5={completedStep5} showChargedEgg={showChargedEgg}>
      <RaysLeft
        src="https://res.cloudinary.com/marco-prouve/image/upload/v1658462916/portfolio-site/easter-eggs/light-rays_zswocb.webp"
        alt="Light Rays Spin Left"
        width="832"
        height="832"
        loading="lazy"
        draggable="false"
      />

      <RaysRight
        src="https://res.cloudinary.com/marco-prouve/image/upload/v1658462916/portfolio-site/easter-eggs/light-rays_zswocb.webp"
        alt="Light Rays Spin Right"
        width="832"
        height="832"
        loading="lazy"
        draggable="false"
      />

      <EggContainer>
        <img
          src="https://res.cloudinary.com/marco-prouve/image/upload/v1658449256/portfolio-site/easter-eggs/egg-charged-glow_mpxkl2.webp"
          alt="Egg Charged"
          width="900"
          height="1180"
          loading="lazy"
          draggable="false"
        />
      </EggContainer>
    </MainContainer>
  )
}

export default EasterEgg
