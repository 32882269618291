import { useContext } from 'react'
import EasterEggContext from '../../../../../context-providers/easter-egg/context/context.js'
import ThemeContext from '../../../../../context-providers/theme/context/context.js'
import locations from '../../constants/locations.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  SectionTitleContainer,
  SectionContentTitle,
  SectionContentSubtitle
} from './styled-components/location-info.js'

// eslint-disable-next-line react/prop-types
const LocationInfo = ({ activeMarker }) => {
  const { completedStep2, completedStep3, setCompletedStep3 } = useContext(EasterEggContext)
  const { lightTheme } = useContext(ThemeContext)
  const location = locations.find((location) => location.id === activeMarker) || {}

  const handleClickChest = () => {
    if (!completedStep3) setCompletedStep3(true)
  }

  return (
    <div className="location-info">
      <div>
        <SectionTitleContainer>
          <SectionContentTitle lightTheme={lightTheme}>Location</SectionContentTitle>
          <FontAwesomeIcon
            icon={solid('map-marker-alt')}
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              fontSize: '1.5rem',
              margin: '0 0 .1rem .5rem',
              color: '#ff7777'
            }}
          />
        </SectionTitleContainer>

        <SectionContentSubtitle lightTheme={lightTheme}>
          <span>{location.location}</span>
        </SectionContentSubtitle>
      </div>

      <div>
        <SectionContentTitle lightTheme={lightTheme}>When</SectionContentTitle>
        <SectionContentSubtitle lightTheme={lightTheme}>
          {location.dates ? location.dates : 'N/A'}
        </SectionContentSubtitle>
      </div>

      <div>
        <SectionContentTitle lightTheme={lightTheme}>Description</SectionContentTitle>
        <SectionContentSubtitle completedStep3={completedStep3} lightTheme={lightTheme}>
          {location.desc}

          {activeMarker === 'hong-kong' && completedStep2 && (
            <img
              src="https://res.cloudinary.com/marco-prouve/image/upload/v1658279680/portfolio-site/easter-eggs/treasure-chest_hdr2gy.webp"
              alt="Treasure Chest"
              width="256"
              height="200"
              loading="lazy"
              onClick={handleClickChest}
            />
          )}
        </SectionContentSubtitle>
      </div>
    </div>
  )
}

export default LocationInfo
