import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 2rem 2rem;

  @media (max-width: 900px) {
    padding: 0 1.5rem;
  }

  @media (max-width: 700px) {
    padding: 0 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 0 1.5rem;
  }
`

export const SectionContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  box-sizing: border-box;
  ${'' /* border-bottom: 1px solid #fff; */}
  ${'' /* border-radius: 1.5rem; */}
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;
  position: relative;

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const SectionTitle = styled.h1`
  display: block;
  padding: 0 4rem;
  text-align: right;
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: 100;
  line-height: 3.6rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;

  @media (max-width: 900px) {
    padding: 0 1.5rem;
    font-size: 3rem;
    line-height: 3.4rem;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 3.1rem;
  }

  @media (max-width: 500px) {
    padding: 0;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`

export const SectionSubtitle = styled.h2`
  display: block;
  width: 100%;
  padding: 0 4rem;
  margin-top: 1rem;
  box-sizing: border-box;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;

  @media (max-width: 900px) {
    padding: 0 1.5rem;
    text-align: center;
  }

  @media (max-width: 400px) {
    padding: 0 1rem;
  }
`

export const InterestsContainer = styled.div`
  display: block;
  width: 100%;
  padding: 3rem 0 0 0;
  overflow: hidden;
`

export const InterestContainer = styled.div`
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  float: left;
  width: ${({ selected }) => (selected ? '65.6%' : '32.3%')};
  padding: ${({ selected }) => (selected ? '26.5%' : '13%')};
  margin: 1% 0.5% 0 0.5%;
  vertical-align: top;
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  transition: width 0.5s ease-in-out, padding 0.5s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    transform: ${({ negativeRotation, selected, isCategory }) =>
      negativeRotation
        ? selected || isCategory
          ? '0'
          : 'rotate(-1deg)'
        : selected || isCategory
        ? '0'
        : 'rotate(1deg)'};

    & .click-prompt-container {
      opacity: ${({ selected }) => (selected ? '0' : '1')};
    }
  }

  & > .interest-desc {
    opacity: ${({ selected }) => (selected ? '1' : '0')};
    pointer-events: ${({ selected }) => (selected ? 'auto' : 'none')};
  }

  @media (max-width: 950px) {
    width: ${({ selected }) => (selected ? '98%' : '48%')};
    padding: ${({ selected }) => (selected ? '40.5%' : '20%')};
    margin: 2% 1% 0 1%;
  }
`

export const ClickPromptContainer = styled.div`
  background-color: #00000099;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`

export const ClickPrompt = styled.span`
  display: inline-block;
  font-family: 'Bungee', cursive;
  font-size: 8rem;
  font-weight: 400;
  line-height: 8rem;
  color: #eee;
  word-break: break-word;
  white-space: pre-line;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    font-size: 6rem;
    line-height: 6rem;
  }

  @media (max-width: 500px) {
    font-size: 5rem;
    line-height: 5rem;
  }

  @media (max-width: 400px) {
    font-size: 4rem;
    line-height: 4rem;
  }
`

export const DescriptionContainer = styled.div`
  background-color: #00000099;
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  cursor: default;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
`

export const DescriptionToggle = styled.span`
  display: inline-block;
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #ffffff;
  word-break: break-word;
  white-space: pre-line;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
`

export const Description = styled.p`
  display: block;
  height: ${({ view }) => (view ? 'auto' : '0')};
  margin-top: ${({ view }) => (view ? '.5rem' : '0')};
  overflow: hidden;
  text-align: left;
  font-family: 'Montserrat Alternates', cursive;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.3rem;
  color: #ffffff;
  word-break: break-word;
  white-space: pre-line;
  ${'' /* transition: all 0.3s ease-in-out; */}
`

export const InterestName = styled.span`
  display: inline-block;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Bungee', cursive;
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 2.8rem;
  color: ${({ lightTheme }) => (lightTheme ? '#333333' : '#eeeeee')};
  word-break: break-word;
  white-space: pre-line;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1100px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  @media (max-width: 950px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  @media (max-width: 800px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  @media (max-width: 550px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 1rem 0.5rem;
  }
`

export const ViewAll = styled.div`
  background: linear-gradient(#80dfff 0%, #00bfff 70%);
  display: block;
  width: 100%;
  height: 100%;
  padding: 34% 0;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

export const ViewAllText = styled.p`
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Bungee', cursive;
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 2.8rem;
  color: #fff;
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1100px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  @media (max-width: 950px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  @media (max-width: 800px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  @media (max-width: 550px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`
