/* eslint-disable react/prop-types */
import { Suspense, useRef, useState, useContext } from 'react'
import SceneAnimation from '../../context-providers/scene-animation/context/context.js'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, OrbitControls, Environment, ContactShadows } from '@react-three/drei'
// import { FaMapMarkerAlt } from 'react-icons/fa'
import EarthModelGLTF from '../../../models/earth.gltf'
import getRadians from '../../../utils/functions/get-radians.js'
import getMarkers from './functions/get-markers.js'

/*
  Auto-generated by: https://github.com/pmndrs/gltfjsx
  author: JasperTobias (https://sketchfab.com/JasperTobias)
  license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
  source: https://sketchfab.com/3d-models/lowpoly-earth-ce0cce9b528b47c7984bf0b2b600d705
  title: LowPoly Earth
  */
const EarthModel = (props) => {
  const ref = useRef()
  const [clicked, setClicked] = useState(false)
  const { activeMarker, setActiveMarker, animateStory } = props
  const { nodes, materials } = useGLTF(EarthModelGLTF)
  const radius = 1.2

  // Subscribe this component to the render-loop, scale the mesh every frame
  useFrame(() => {
    if (!clicked && animateStory) {
      ref.current.rotation.z -= 0.004
    }
  })

  return (
    <group
      ref={ref}
      rotation={[getRadians(-90), getRadians(5), getRadians(-35)]}
      {...props}
      dispose={null}
      onClick={() => setClicked(true)}>
      <mesh
        geometry={nodes['URF-Height_Lampd_Ice_0'].geometry}
        material={materials.Lampd_Ice}
        material-roughness={0.5}
        material-metalness={0.2}
      />
      <mesh
        geometry={nodes['URF-Height_watr_0'].geometry}
        material={materials.watr}
        material-color="#0086b3"
        material-roughness={0.3}
        material-metalness={0.3}
      />
      <mesh
        geometry={nodes['URF-Height_Lampd_0'].geometry}
        material={materials.Lampd}
        material-color="lightgreen"
        material-roughness={0.6}
        material-metalness={0.4}>
        {getMarkers(radius).map((marker) => {
          const { id, rotation, position } = marker

          return (
            <LocationMarker
              key={id}
              id={id}
              position={position}
              rotation={rotation}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
            />
          )
        })}
      </mesh>
    </group>
  )
}

// Let's make the marker into a component so that we can abstract some shared logic
const LocationMarker = (props) => {
  const [hovered, setHovered] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [occluded, setOccluded] = useState(false)
  const { id, rotation, position, activeMarker, setActiveMarker } = props
  let markerColor = '#ffa64d'

  if (activeMarker === id) {
    markerColor = '#ff7777'
  }

  if (hovered) {
    markerColor = '#ff7777'
  }

  if (clicked) {
    markerColor = '#ff9999'
  }

  return (
    <mesh
      key={id}
      rotation={rotation}
      position={position}
      scale={occluded ? 0 : 0.08}
      onClick={() => setActiveMarker(id)}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onPointerDown={() => setClicked(true)}
      onPointerUp={() => setClicked(false)}
      occlude
      onOcclude={setOccluded}
      transform>
      <octahedronGeometry args={[1, 0]} />
      <meshStandardMaterial color={markerColor} roughness={0.2} metalness={0.7} />
    </mesh>
  )
}

const EarthScene = ({ activeMarker, setActiveMarker, scaleDownScene }) => {
  const ref = useRef()
  const { animateStory } = useContext(SceneAnimation)

  return (
    <Canvas
      gl={{
        // powerPreference: 'high-performance',
        stencil: false,
        antialias: false
      }}
      dpr={[1, 1.3]}
      camera={{ position: [3.7, 0.4, 0], fov: 47, near: 1, far: 5 }}
      style={{
        // backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: '1.5rem',
        width: '100%',
        height: '32rem',
        cursor: 'pointer'
      }}>
      {/* <color attach="background" args={['#ffffff']} /> */}

      {/* Enable Orbital Controls to enable orbit controls */}
      <OrbitControls ref={ref} enableZoom={false} />

      {/* Lighting */}
      <hemisphereLight position={[0, 3, 0]} intensity={0.5} />

      {/* Objects */}
      <Suspense fallback={null}>
        <EarthModel
          position={[0, 0.2, 0]}
          scale={scaleDownScene ? 0.85 : 0.99}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
          animateStory={animateStory}
        />
        <Environment path="/" files="potsdamer_platz_1k.hdr" />
        <ContactShadows
          scale={2.8}
          height={1}
          width={1}
          position={[0, -1, 0]}
          far={1.5}
          blur={4}
          opacity={0.5}
          frames={1}
          resolution={32}
        />
      </Suspense>
    </Canvas>
  )
}

export default EarthScene
