import ReactDOM from 'react-dom/client'
import { GlobalStyles } from './res/global-styles'
import App from './components/App'
// import reportWebVitals from './reportWebVitals'
import EasterEggContextProvider from './components/context-providers/easter-egg/easter-egg.js'
import SceneAnimationContextProvider from './components/context-providers/scene-animation/scene-animation.js'
import ThemeContextProvider from './components/context-providers/theme/theme.js'
import config from './config.js'

const root = ReactDOM.createRoot(document.getElementById('root'))

// eslint-disable-next-line no-undef
config.debug && console.log('[REACT_APP_NODE_ENVIRONMENT]: ', process.env.REACT_APP_NODE_ENV)
// eslint-disable-next-line no-undef
config.debug && console.log('[NODE_ENVIRONMENT]: ', process.env.NODE_ENV)

root.render(
  <EasterEggContextProvider>
    <SceneAnimationContextProvider>
      <ThemeContextProvider>
        <GlobalStyles />
        <App />
      </ThemeContextProvider>
    </SceneAnimationContextProvider>
  </EasterEggContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
