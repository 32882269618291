const interests = [
  {
    id: 'surfing',
    title: 'Surfing',
    index: 0,
    items: [
      {
        desc: 'Sunset surf at Playa Carmen Beach, Costa Rica',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166601/portfolio-site/interests/costa-rica-1_luczlt_odtbfz.webp'
      },
      {
        desc: 'Post-surf sunset in Santa Teresa, Costa Rica',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166600/portfolio-site/interests/costa-rica-2_cfkc9o_fopx4o.webp'
      },
      {
        desc: 'Castle Rock Beach, California',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166602/portfolio-site/interests/castle-rock-beach_c4wwt2_j8frai.webp'
      }
    ]
  },
  {
    id: 'exploring',
    title: 'Exploring',
    index: 1,
    items: [
      {
        desc: 'Koh Samui, Thailand',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166603/portfolio-site/interests/koh-samui-1_hqtii7_sfzdsg.webp'
      },
      {
        desc: 'Fulfilling hike up to the top of Wengen in Switzerland with a beautiful view of Lauterbrunnen Valley.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993317/portfolio-site/interests/qsdiarkllay2eviz9apd.webp'
      },
      {
        desc: 'A little morning sunrise in Capri, Italy',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166600/portfolio-site/interests/capri-1_ribczj_hvajkr.webp'
      },
      // {
      //   desc: 'Faraglioni rock formations in Capri, Italy',
      //   img: 'https://i.imgur.com/btHjRyV.jpg'
      // },
      {
        desc: 'Playa Carmen Beach, Costa Rica',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166601/portfolio-site/interests/costa-rica-3_dqtwrd_jmpgam.webp'
      },
      {
        desc: 'Just a bit of sailing in Bandol, France with the gang.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/st-tropez-1_lkdsn5_lmkqtz.webp'
      },
      {
        desc: 'St. Tropez, France',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993316/portfolio-site/interests/enpgpk3nfemi0jbgim8k.webp'
      }
      // {
      //   desc: 'Burj Khalifa in Dubai',
      //   img: 'https://i.imgur.com/L14oD0E.jpg'
      // }
    ]
  },
  {
    id: 'wood-work',
    title: 'Wood work',
    index: 2,
    items: [
      {
        desc: 'Prouvé-style LED lamp made with cement and walnut.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166600/portfolio-site/interests/concrete-lamp-1_zv1gj5_axjpi6.webp'
      },
      {
        desc: "This moroccan-inspired couch seating was a big undertaking but turned out better than I'd hoped.",
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166602/portfolio-site/interests/moroccan-seating_avxbrz_oycdw2.webp'
      },
      // {
      //   desc: 'Handmade workbench where all the magic happens.',
      //   img: 'https://i.imgur.com/bViNGzC.jpg'
      // },
      {
        desc: 'Made a large ventilator cabinet for a tall 3D printer in order to filter out harmful fumes and organize my materials.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166600/portfolio-site/interests/3d-printer-enclosure_zxfygh_jwgmt6.webp'
      },
      {
        desc: 'Better view of the ventilation unit and my custom made workbench.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166600/portfolio-site/interests/workbench-side-view_miftf9_dscucx.webp'
      },
      {
        desc: 'Custom frames for metal anime artwork that will each be embedded with LEDs to match their containing anime artwork.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993317/portfolio-site/interests/nrahmk701j9vhklqnqsr.webp'
      },
      {
        desc: 'Finished custom frames showcasing metal anime artwork with embedded LEDs.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993316/portfolio-site/interests/sab7olhnhavhnydidaty.webp'
      },
      {
        desc: 'Custom floating desk made with butcher blocks showing custom Desk and PC setup.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993316/portfolio-site/interests/w8vgsmygrfym2pxbprlv.webp'
      }
    ]
  },
  {
    id: 'engineering',
    title: 'Engineering',
    index: 3,
    items: [
      {
        desc: 'Jetson Nano powering a small LCD initially made for controlling robotic arm motors.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166601/portfolio-site/interests/jetson-nano-lcd_gs5b19_luqf0t.webp'
      },
      {
        desc: 'Rear-view of a frameless smart-mirror showing electronics built around a Raspberry Pi and 27-inch LCD monitor.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166601/portfolio-site/interests/frameless-mirror-1_nssbtz_rogzbh.webp'
      },
      {
        desc: 'Front-view of custom frameless smart-mirror powered by a Raspberry Pi.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166601/portfolio-site/interests/frameless-mirror-2_m8s9vi_pvjjsh.webp'
      },
      {
        desc: 'V1 of robotic arm (lower arm only) used for testing motor motion.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/motor-prototype_tsoxfu_l8bzkp.webp'
      },
      {
        desc: 'RC plane modified to incorporate GPS, auto-pilot, and first-person flying (using camera embeddd in nose).',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/rc-plane_sd9kfh_szttx1.webp'
      },
      {
        desc: 'Beginning of an intense and complex electric motorcycle build. Bought 288 LiFePO4 cells and had to build this rack to top balance them in parallel using aluminum bus bars.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993316/portfolio-site/interests/uz7i5umk08vmnxcebmut.webp'
      },
      {
        desc: 'Completed 12p24s battery pack for the electric motorcycle. Still need to attach the BMS and build a case but hard parts over!',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1720993316/portfolio-site/interests/c63xcamnjg3ejz2khgbq.webp'
      }
    ]
  },
  {
    id: 'motorcycles',
    title: 'Motorcycles',
    index: 4,
    items: [
      {
        desc: 'Taking my good friend for a stroll on the new Scrambler.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166603/portfolio-site/interests/motorcycle-1_n0npfi_qmoqdl.webp'
      },
      {
        desc: 'Just taking a ride :)',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166603/portfolio-site/interests/motorcycle-2_w77chj_elfa6d.webp'
      },
      {
        desc: 'Short rental with this guy for a bit of exploring.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166603/portfolio-site/interests/motorcycle-3_rq7es7_zdtkzv.webp'
      }
    ]
  },
  {
    id: 'skating',
    title: 'Skating',
    index: 6,
    items: [
      {
        desc: 'Longboarded out to the pier for a cool sunset with a friend.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/skating-sunset_wbcpi6_fabiwu.webp'
      },
      {
        desc: 'Swung by the Venice skate park for some quiet spectating.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/venice-skate-park_jre5jd_noarzr.webp'
      },
      {
        desc: 'Just a little cruise down the Speedway.',
        img: 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166604/portfolio-site/interests/skating-1_bevglx_eiiyjj.webp'
      }
    ]
  }
]

export default interests
