import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  padding: 6rem 2rem 2rem 2rem;

  @media (max-width: 700px) {
    padding: 5.2rem 1.5rem 1.5rem 1.5rem;
  }
`

export const SceneContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${({ width }) => width || '100%'};
  height: 100%;
  min-height: 30rem;
  border-radius: 1rem;
  position: relative;
`

export const AllAvatarsBackground = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
`
