import { useContext } from 'react'
import ThemeContext from '../../../context-providers/theme/context/context.js'
import EasterEggContext from '../../../context-providers/easter-egg/context/context.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  MainContainer,
  NavigationLinksContainer,
  NavigationLink,
  ActionsContainer,
  ThemeContainer,
  PathContainer,
  CurvedPath,
  MoonBox,
  MoonIconContainer,
  SunBox,
  SunIconContainer,
  ThemeButton,
  Signature,
  SocialLink
} from './styled-components/side-nav.js'

// eslint-disable-next-line react/prop-types
const Header = ({ open, setOpen }) => {
  const { lightTheme, setLightTheme } = useContext(ThemeContext)
  const {
    completedStep1,
    setCompletedStep1,
    completedStep4,
    completedStep5,
    setCompletedStep5,
    numDaysCharged,
    setNumDaysCharged
  } = useContext(EasterEggContext)

  const handleToggleTheme = () => {
    if (completedStep4 && !completedStep5 && !lightTheme) {
      if (numDaysCharged < 3) {
        const tmpCurrDaysCharged = numDaysCharged

        setNumDaysCharged(tmpCurrDaysCharged + 1)

        if (tmpCurrDaysCharged + 1 === 3) {
          setCompletedStep5(true)
        }
      }
    }

    setLightTheme(!lightTheme)

    if (!completedStep1) setCompletedStep1(true)
  }

  return (
    <MainContainer open={open} lightTheme={lightTheme}>
      <NavigationLinksContainer lightTheme={lightTheme}>
        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#about-me" aria-label="About Me">
              About Me
            </a>
            <div />
          </NavigationLink>
        </div>

        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#story" aria-label="Story">
              Story
            </a>
            <div />
          </NavigationLink>
        </div>

        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#skills" aria-label="Skills">
              Skills
            </a>
            <div />
          </NavigationLink>
        </div>

        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#experience" aria-label="Experience">
              Experience
            </a>
            <div />
          </NavigationLink>
        </div>

        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#interests" aria-label="Interests">
              Interests
            </a>
            <div />
          </NavigationLink>
        </div>

        <div>
          <NavigationLink onClick={() => setOpen(false)}>
            <a href="#contact-me" aria-label="Contact Me">
              Contact Me
            </a>
            <div />
          </NavigationLink>
        </div>
      </NavigationLinksContainer>

      <ActionsContainer>
        <ThemeContainer>
          <PathContainer>
            <CurvedPath lightTheme={lightTheme} />
          </PathContainer>

          <MoonBox lightTheme={lightTheme}>
            <MoonIconContainer lightTheme={lightTheme}>
              <FontAwesomeIcon icon={solid('moon')} />
            </MoonIconContainer>
          </MoonBox>

          <SunBox lightTheme={lightTheme}>
            <SunIconContainer lightTheme={lightTheme}>
              <FontAwesomeIcon icon={solid('sun')} />
            </SunIconContainer>
          </SunBox>

          <ThemeButton lightTheme={lightTheme} onClick={handleToggleTheme}>
            <FontAwesomeIcon icon={solid('moon')} />
            <FontAwesomeIcon icon={solid('sun')} />
          </ThemeButton>
        </ThemeContainer>

        <Signature>
          Designed and developed by <b>Marco Prouv&#233;</b>
        </Signature>

        <a href="mailto:me@marcoprouve.com" target="_blank" rel="noreferrer" aria-label="Email">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/email-black_fye9mn_vvoxgo.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/email-white_rwd6nw_dszxvd.webp'
            }
            alt="Email Icon"
            width="512"
            height="512"
            loading="lazy"
          />
        </a>

        <a
          href="https://www.instagram.com/marcoprouve/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/instagram-black_dmexid_sicfkq.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/instagram-white_asbtvm_ir7oph.webp'
            }
            alt="Instagram Icon"
            width="512"
            height="512"
            loading="lazy"
          />
        </a>

        <a
          href="https://twitter.com/marco_prouve"
          target="_blank"
          rel="noreferrer"
          aria-label="Twitter">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/twitter-black_onwryk_wzxdh1.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/twitter-white_lyul82_jujymk.webp'
            }
            alt="Twitter Icon"
            width="512"
            height="512"
            loading="lazy"
          />
        </a>

        <a
          href="https://www.linkedin.com/in/marcoprouve/"
          target="_blank"
          rel="noreferrer"
          aria-label="LinkedIn">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/linkedin-black_fdlmrq_r5wh0d.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/linkedin-white_gyzvhn_sodklb.webp'
            }
            alt="LinkedIn Icon"
            width="512"
            height="512"
            loading="lazy"
            style={{ marginBottom: '.3rem' }}
          />
        </a>

        <a href="https://github.com/mprouve" target="_blank" rel="noreferrer" aria-label="GitHub">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/github-black_skpb8m_sjbpdf.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/github-white_l9df4a_bfdtwp.webp'
            }
            alt="GitHub Icon"
            width="512"
            height="512"
            loading="lazy"
          />
        </a>

        <a
          href="https://discordapp.com/users/328009884660662272/"
          target="_blank"
          rel="noreferrer"
          aria-label="Discord">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/discord-black_w1b7ph_schvhu.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166619/portfolio-site/social/discord-white_n9msvy_fuassb.webp'
            }
            alt="Discord Icon"
            width="512"
            height="512"
            loading="lazy"
            style={{ marginBottom: '.1rem' }}
          />
        </a>

        <a
          href="https://www.reddit.com/user/marcoprouve"
          target="_blank"
          rel="noreferrer"
          aria-label="Reddit">
          <SocialLink
            src={
              lightTheme
                ? 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/reddit-black_du1srr_e7r4ip.webp'
                : 'https://res.cloudinary.com/marco-prouve/image/upload/v1658166620/portfolio-site/social/reddit-white_ynyptp_wu8jxd.webp'
            }
            alt="Reddit Icon"
            width="512"
            height="512"
            loading="lazy"
            style={{ marginBottom: '.3rem' }}
          />
        </a>
      </ActionsContainer>
    </MainContainer>
  )
}

export default Header
