import { useState, useEffect, useRef, useContext } from 'react'
import ThemeContext from '../../../context-providers/theme/context/context.js'
// import useLazyImage from '../../../custom-hooks/use-lazy-image/use-lazy-image.js'
// import placeholderImg from '../../../../media/placeholders/interest-placeholder.webp'
import interests from './constants/interests.js'
import {
  MainContainer,
  SectionContainer,
  SectionTitle,
  SectionSubtitle,
  InterestsContainer,
  InterestContainer,
  ClickPromptContainer,
  ClickPrompt,
  DescriptionContainer,
  DescriptionToggle,
  Description,
  InterestName,
  ViewAll,
  ViewAllText
} from './styled-components/interests.js'

const Interests = () => {
  const { lightTheme } = useContext(ThemeContext)
  const sectionRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [scrolledOver, setScrolledOver] = useState(false)
  const [activeItem, setActiveItem] = useState('')
  const [toggle, setToggle] = useState(false)
  const [initialLimit, setInitialLimit] = useState(6)
  const [viewAll, setViewAll] = useState(false)
  const initialLimitRef = useRef(6)
  const items = []
  let itemCounter = 0

  const handleSelect = (e) => {
    if (!activeItem) {
      setActiveItem(e.target.id)
    } else if (activeItem === e.target.id) {
      setActiveItem('')
    } else {
      setActiveItem(e.target.id)
    }
  }

  const handleToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setToggle(!toggle)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const windowBottomScrollY = innerHeight + pageYOffset

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    const handleResize = () => {
      if (window.innerWidth > 950) {
        if (initialLimitRef.current !== 6) {
          setInitialLimit(6)
        }
      } else {
        if (initialLimitRef.current !== 5) {
          setInitialLimit(5)
        }
      }
    }

    if (window.innerWidth <= 950 && !loaded) {
      setInitialLimit(5)
    }

    if (!loaded) setLoaded(true)

    window.addEventListener('scroll', handleScroll, { passive: true })
    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    initialLimitRef.current = initialLimit
  }, [initialLimit])

  for (let i = 0; i < interests.length; i++) {
    const interest = interests[i]

    if (itemCounter >= initialLimit && !viewAll) {
      break
    }

    items.push(
      <InterestContainer
        key={`interests-category-${interest.id}`}
        selected={false}
        isCategory={true}
        style={{ background: 'none', cursor: 'default' }}>
        <InterestName lightTheme={lightTheme}>{interest.title}</InterestName>
      </InterestContainer>
    )

    for (let j = 0; j < interest.items.length; j++) {
      const item = interest.items[j]
      const negativeRotation = Math.round(Math.random())

      if (itemCounter >= initialLimit && !viewAll) {
        items.push(
          <InterestContainer
            key={`interests-view-all`}
            selected={false}
            isCategory={true}
            style={{ background: 'none', cursor: 'default' }}>
            <ViewAll onClick={() => setViewAll(true)}>
              <ViewAllText>View All</ViewAllText>
            </ViewAll>
          </InterestContainer>
        )

        break
      } else {
        // const image = useLazyImage(item.img)

        items.push(
          <InterestContainer
            key={`interests-item-${interest.id}-${j}`}
            id={`${interest.id}-${j}`}
            selected={`${interest.id}-${j}` === activeItem}
            negativeRotation={negativeRotation}
            isCategory={false}
            onClick={handleSelect}
            style={{ backgroundImage: `url(${item.img})` }}
            // style={{ backgroundImage: `url(${image || placeholderImg})` }}
          >
            <ClickPromptContainer
              className="click-prompt-container"
              selected={`${interest.id}-${j}` === activeItem}>
              <ClickPrompt className="click-prompt">?</ClickPrompt>
            </ClickPromptContainer>

            <DescriptionContainer
              className="interest-desc"
              selected={`${interest.id}-${j}` === activeItem}
              onClick={(e) => e.stopPropagation()}>
              <DescriptionToggle onClick={handleToggle}>
                {toggle ? 'Hide Description' : 'View Description'}
              </DescriptionToggle>
              <Description view={toggle}>{item.desc}</Description>
            </DescriptionContainer>
          </InterestContainer>
        )
      }

      if (i === interests.length - 1 && j === interest.items.length - 1) {
        items.push(
          <InterestContainer
            key={`interests-view-all`}
            selected={false}
            isCategory={true}
            style={{ background: 'none', cursor: 'default' }}>
            <ViewAll onClick={() => setViewAll(false)}>
              <ViewAllText>View Less</ViewAllText>
            </ViewAll>
          </InterestContainer>
        )

        break
      }

      itemCounter += 1
    }
  }

  return (
    <MainContainer id="interests">
      <SectionContainer ref={sectionRef} scrolledOver={scrolledOver}>
        <SectionTitle lightTheme={lightTheme}>Interests</SectionTitle>
        <SectionSubtitle lightTheme={lightTheme}>
          I focus on personal growth through learning new skills, staying active, and exploring the
          world
        </SectionSubtitle>

        <InterestsContainer>{items}</InterestsContainer>
      </SectionContainer>
    </MainContainer>
  )
}

export default Interests
