import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 2rem 2rem 4rem 2rem;

  @media (max-width: 900px) {
    padding: 4rem 1.5rem 4rem 1.5rem;
  }

  @media (max-width: 700px) {
    padding: 3rem 1.5rem 3rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 3rem 1.5rem 3rem 1.5rem;
  }
`

export const SectionContainer = styled.div`
  ${'' /* background: #25262a; */}
  background: linear-gradient(#ff8888 30%, #ff5555 100%);
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 4rem 4rem 4rem 4rem;
  box-sizing: border-box;
  ${'' /* border-bottom: 1px solid #fff; */}
  border-radius: 1.5rem;
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;
  position: relative;

  @media (max-width: 900px) {
    width: 90%;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const Title = styled.p`
  display: block;
  text-align: center;
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: 100;
  line-height: 3.6rem;
  color: #fff;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const Subtitle = styled.p`
  display: block;
  width: 80%;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #fff;

  @media (max-width: 900px) {
    margin-top: 1.8rem;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const ButtonsContainer = styled.div`
  display: block;
  margin-top: 2rem;
  text-align: center;
`
