import { createContext } from 'react'

// Define Context variable to be able to pass props down the entire tree of child components
const Context = createContext({
  animateStory: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnimateStory: () => {},
  animateAboutMe: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnimateAboutMe: () => {}
})

export default Context
