import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  ${'' /* height: 100%; */}
  position: absolute;
  top: 4.5rem;
  left: 0;

  @media (max-width: 1150px) {
    top: 4.6rem;
  }

  @media (max-width: 1000px) {
    top: 2.6rem;
  }

  @media (max-width: 800px) {
    top: 2.6rem;
  }

  @media (max-width: 600px) {
    top: 3rem;
  }

  @media (max-width: 450px) {
    top: 2.5rem;
  }

  @media (max-width: 380px) {
    top: 2rem;
  }
`

export const TextContainer = styled.div`
  display: block;
  width: 100%;
  ${'' /* max-width: 70rem; */}
  margin: 0 auto;
  ${
    '' /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  }
`

export const Title = styled.h1`
  display: block;
  width: 100%;
  padding: 0 4rem;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Bungee', sans-serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: 5.3rem;
  letter-spacing: -0.05rem;
  color: #fff;

  @media (max-width: 1150px) {
    font-size: 4.2rem;
    line-height: 4.5rem;
  }

  @media (max-width: 1000px) {
    font-size: 4.4rem;
    line-height: 4.9rem;
  }

  @media (max-width: 900px) {
    font-size: 4.2rem;
    line-height: 4.6rem;
  }

  @media (max-width: 750px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @media (max-width: 600px) {
    padding: 0 3rem;
    font-size: 2.5rem;
    line-height: 2.9rem;
  }

  @media (max-width: 500px) {
    padding: 0 3rem;
    font-size: 2.3rem;
    line-height: 2.7rem;
  }

  @media (max-width: 450px) {
    padding: 0 1rem;
  }
`

export const Subtitle = styled.h1`
  display: block;
  width: 100%;
  margin-top: 0.7rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.05rem;
  color: white;

  @media (max-width: 1150px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  @media (max-width: 900px) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  @media (max-width: 750px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  @media (max-width: 600px) {
    padding: 0 3rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (max-width: 500px) {
    width: 90%;
    margin: 0.8rem auto 0 auto;
    padding: 0 3rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (max-width: 450px) {
    padding: 0 2rem;
  }
`
