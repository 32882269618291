import styled, { keyframes } from 'styled-components'

const spinningAnimationLeft = keyframes`
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
`

const spinningAnimationRight = keyframes`
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`

export const MainContainer = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${({ completedStep5, showChargedEgg }) =>
    completedStep5 && showChargedEgg
      ? 'translate(-50%, -50%) scale(1)'
      : 'translate(-50%, -50%) scale(0)'};
  transition: transform 2s ease-in-out;
  z-index: 998;
`

export const EggContainer = styled.div`
  display: block;
  width: 100%;
  height: 22rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 998;

  & > img {
    display: block;
    width: auto;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1000px) {
    height: 17rem;
  }

  @media (max-width: 700px) {
    height: 13rem;
  }

  @media (max-width: 500px) {
    height: 11rem;
  }
`

export const RaysLeft = styled.img`
  display: block;
  width: 35rem;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 997;
  animation: ${spinningAnimationLeft} 20s infinite;
  animation-timing-function: linear;

  @media (max-width: 1000px) {
    width: 28rem;
  }

  @media (max-width: 700px) {
    width: 21rem;
  }

  @media (max-width: 500px) {
    width: 18rem;
  }
`

export const RaysRight = styled.img`
  display: block;
  width: 35rem;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 997;
  animation: ${spinningAnimationRight} 20s infinite;
  animation-timing-function: linear;

  @media (max-width: 1000px) {
    width: 28rem;
  }

  @media (max-width: 700px) {
    width: 21rem;
  }

  @media (max-width: 500px) {
    width: 18rem;
  }
`
