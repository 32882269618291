import { createContext } from 'react'

// Define Context variable to be able to pass props down the entire tree of child components
const Context = createContext({
  lightTheme: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLightTheme: () => {}
})

export default Context
