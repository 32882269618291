import { useState, useEffect, useRef, useContext } from 'react'
import SceneAnimation from '../../../context-providers/scene-animation/context/context.js'
import ThemeContext from '../../../context-providers/theme/context/context.js'
import AboutMeScene from '../../../scenes/about-me-scene/about-me-scene.js'
// import ScrollBar from '../../../reuseable/scroll-bar/scroll-bar.js'
import ScrollIndicator from '../../../reuseable/scroll-indicator/scroll-indicator.js'
import {
  MainContainer,
  SectionContainer,
  ContentContainer,
  Title,
  MainTextContainer,
  MainText,
  SceneContainer
} from './styled-components/about-me.js'

const AboutMe = () => {
  const { lightTheme } = useContext(ThemeContext)
  const { animateAboutMe, setAnimateAboutMe } = useContext(SceneAnimation)
  const sectionRef = useRef(null)
  const scrollContainerRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [scrolledOver, setScrolledOver] = useState(false)
  const [scaleDownScene, setScaleDownScene] = useState(false)
  const [enableAnimation, setEnableAnimation] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef?.current) {
        const { innerHeight, pageYOffset } = window
        const { offsetTop } = sectionRef.current
        const { top, bottom } = sectionRef.current.getBoundingClientRect()
        const windowBottomScrollY = innerHeight + pageYOffset
        const isTopVisible = top > 0 && top < innerHeight
        const isBottomVisible = bottom > 0 && bottom < innerHeight

        // Set Scene animations of in focus or not
        if (isTopVisible || isBottomVisible) {
          if (!enableAnimation) {
            // console.log('ANIMATE - About Me')
            setEnableAnimation(true)
          }
        } else {
          if (enableAnimation) {
            // console.log("DON'T ANIMATE - About Me")
            setEnableAnimation(false)
          }
        }

        if (windowBottomScrollY >= offsetTop && !scrolledOver) {
          setScrolledOver(true)
        }
      }
    }

    if (window.innerWidth <= 900 && !loaded) {
      setScaleDownScene(true)
    }

    if (!loaded) setLoaded(true)

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [enableAnimation])

  useEffect(() => {
    if (animateAboutMe !== enableAnimation) {
      setAnimateAboutMe(enableAnimation)
    }
  }, [enableAnimation])

  return (
    <MainContainer id="about-me">
      <SectionContainer ref={sectionRef} scrolledOver={scrolledOver}>
        <ContentContainer>
          <Title>About Me</Title>

          <MainTextContainer>
            <MainText ref={scrollContainerRef}>
              I enjoy innovating more than anything, and I learn through the challenges of creating.
              I graduated with a BS in Computer Science in 2016 and soon after became a full-stack
              engineer, which opened the door to pursuing my passions and interests. I love
              expanding my knowledge of growing technologies - whether they lie in software,
              mechanics, or electronics - and applying it to building existing and innovative
              solutions. Through doing what I love I&#39;ve become great at what I do.
              <br />
              <br />
              Co-founding two startups has given me more experience across all facets of business
              and product development than I could&#39;ve ever hoped for. Not only have I learned
              from my individual mistakes and accomplishments, but the guidance from many differing
              perspectives has been extremely valuable to my growth.
              <br />
              <br />
              Throughout my professional career I&#39;ve been heavily involved in planning and
              executing core business strategies / marketing efforts as well as facilitating the
              growth of invaluable partnerships and networks. But more importantly, I&#39;ve
              excelled at architecting full-stack (MERN) applications, from conceptualization to
              users and revenue, always with scalability and user experience in mind.
              <br />
              <br />I love connecting with great people, hearing new perspectives, and exploring
              creative ideas, so please reach out to me (
              <a href="mailto:me@marcoprouve.com">
                <u>me@marcoprouve.com</u>
              </a>
              ) if you want to talk about upcoming opportunities, growing technologies, or even just
              want to chat.
            </MainText>

            {/* {scrollContainerRef.current && <ScrollBar scrollContainerRef={scrollContainerRef} color={'#ffffff'} style={{bottom: '-2px'}}/>} */}
          </MainTextContainer>

          <ScrollIndicator didScroll={false} style={{ marginTop: '1.5rem' }} />
        </ContentContainer>

        <SceneContainer>
          <svg
            height="100%"
            width="100%"
            fill={lightTheme ? '#ffffff' : '#26222a'}
            // stroke="red"
            viewBox="0 0 100 100"
            preserveAspectRatio="none">
            <path d="M85,0 L100,0 L100,100 L20,100 L85,0" />
            {/* <path d="M90,0 L100,0 L100,100 L20,100 L90,10 Q98,.1 90,0" /> */}
          </svg>
          {/* <svg
            height="100%"
            width="100%"
            fill="#00bfff"
            // stroke="red"
            viewBox="0 0 100 100"
            preserveAspectRatio="none">
            <path d="M20,100 L55,50 L90,100 L20,100" />
          </svg> */}
          <AboutMeScene scaleDownScene={scaleDownScene} />
        </SceneContainer>
      </SectionContainer>
    </MainContainer>
  )
}

export default AboutMe
