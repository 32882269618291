import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;

  @media (max-width: 700px) {
    padding: 0.5rem 1.5rem 2rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 1rem 1.5rem 2rem 1.5rem;
  }
`

export const SectionContainer = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? '#25262a' : '#1D1A1F')};
  display: block;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  margin: 0 auto;
  padding: 4rem 3rem 4.5rem 3rem;
  border-radius: 1.5rem;
  opacity: ${({ scrolledOver }) => (scrolledOver ? '1' : '0')};
  transition: opacity 1.2s ease-in-out;

  @media (max-width: 900px) {
    width: 90%;
    padding: 2.5rem 2.5rem 2.8rem 2.5rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const TitleContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0 4rem 0.3rem 4rem;
  text-align: center;

  @media (max-width: 600px) {
    padding: 0 3rem;
  }

  @media (max-width: 500px) {
    padding: 0;
    font-size: 1.8rem;
  }

  @media (max-width: 400px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const Title = styled.h1`
  display: none;
  ${'' /* padding-bottom: 0.5rem; */}
  ${'' /* border-bottom: 3px solid #ff7777; */}
  font-family: 'Bungee', cursive;
  font-size: 3rem;
  font-weight: regular;
  line-height: 3rem;
  color: #eee;

  @media (max-width: 900px) {
    display: inline-block;
  }

  @media (max-width: 600px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }
`

export const ContentContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto 0 auto;
  text-align: left;

  @media (max-width: 900px) {
    margin: 2rem auto 0 auto;
  }

  ${
    '' /* @media (max-width: 500px) {
    margin: 2rem auto 0 auto;
  } */
  }
`

export const Categories = styled.div`
  display: inline-block;
  width: 47%;
  margin-right: 6%;
  vertical-align: top;

  & > div:not(:first-child) {
    margin-top: 0.4rem;
  }

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    margin-right: 0;
  }
`

export const Category = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? '#2d2e33' : '#26222a')};
  display: block;
  width: 100%;
  padding: ${({ selected }) => (selected ? '1.5rem 4rem 1.5rem 2rem' : '1rem 4rem 1rem 2rem')};
  box-sizing: border-box;
  border-radius: 0.5rem;
  text-align: left;
  box-shadow: ${({ selected }) => (selected ? '0px 15px 20px 2px #00000022' : 'none')};
  cursor: pointer;
  position: relative;
  z-index: ${({ selected }) => (selected ? '1' : '0')};
  transition: box-shadow 0.2s ease-in-out, padding 0.2s ease-in-out,
    background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ lightTheme }) => (lightTheme ? '#2d2e3399' : '#26222a99')};
  }

  &:active {
    background-color: ${({ lightTheme }) => (lightTheme ? '#2d2e3355' : '#26222a55')};
  }

  & > p:nth-child(1) {
    color: ${({ selected }) => (selected ? '#ff7777' : '#eee')};
  }

  @media (max-width: 900px) {
    padding: 1rem 3.2rem 1rem 1.2rem;
  }
`

export const CategoryName = styled.p`
  position: relative;
  font-family: 'Bungee', sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  line-height: 1.5rem;
  transition: color 0.2s ease-in-out;
  pointer-events: none;

  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`

export const SkillsContainer = styled.div`
  display: inline-block;
  width: 47%;
  vertical-align: top;
  text-align: left;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
  }
`

export const SkillsTitle = styled.span`
  display: inline-block;
  ${'' /* padding-bottom: 0.4rem; */}
  ${'' /* border-bottom: 3px solid #ff7777; */}
  font-family: 'Bungee', cursive;
  font-size: 3.6rem;
  font-weight: regular;
  line-height: 3.6rem;
  color: #eee;

  @media (max-width: 900px) {
    display: none;
  }
`

export const Skills = styled.div`
  display: block;
  margin-top: 1.5rem;

  @media (max-width: 900px) {
    margin-top: 1.5rem;
  }
`

export const Skill = styled.div`
  background-color: #00000000;
  display: inline-block;
  min-width: 1rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.3rem 0.5rem;
  vertical-align: middle;
  border: 1.5px solid #fff;
  border-radius: 0.3rem;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: #eee;
  transform: ${({ completedStep2 }) => (completedStep2 ? 'scale(0)' : 'scale(1)')};
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, transform 0.4s ease-in-out;
  position: relative;

  &:hover {
    color: #ff7777;
    border-color: #ff7777;
  }

  & > img {
    display: block;
    height: 1rem;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ completedStep2 }) =>
      completedStep2 ? ' translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
    transition: transform 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: ${({ completedStep2 }) =>
        completedStep2 ? ' translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1.1)'};
    }

    &:active {
      transform: ${({ completedStep2 }) =>
        completedStep2 ? ' translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
    }
  }
`
