import getSphereCoordinates from '../../../../utils/functions/get-sphere-coordinates.js'

const getMarkers = (radius) => {
  return [
    // Cartesian Coordinate Test Markers
    // {
    //   id: 'x-axis',
    //   rotation: [0, 0, 0],
    //   position: [1.2, 0, 0]
    // },
    // {
    //   id: 'y-axis',
    //   rotation: [0, 0, 0],
    //   position: [0, 1.2, 0]
    // },
    // {
    //   id: 'z-axis',
    //   rotation: [0, 0, 0],
    //   position: [0, 0, 1.2]
    // },
    // Production Markers
    {
      id: 'los-angeles',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 16, 73)
    },
    {
      id: 'new-york',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 61, 54)
    },
    {
      id: 'chicago',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 40, 56)
    },
    {
      id: 'wisconsin',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 33, 52)
    },
    {
      id: 'tokyo',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, -70, 64)
    },
    {
      id: 'singapore',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, -120, 100)
    },
    {
      id: 'hong-kong',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, -108, 72)
    },
    {
      id: 'france',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 120, 54)
    },
    {
      id: 'honduras',
      rotation: [0, 0, 0],
      position: getSphereCoordinates(radius, 36, 83)
    }
  ]
}

export default getMarkers
