import styled from 'styled-components'

export const MainContainer = styled.div`
  margin-top: ${({ open }) => (open ? '0' : '0rem')};

  & > svg {
    display: block;
    margin-top: ${({ open }) => (open ? '-0.2rem' : '0')};
    height: ${({ open }) => (open ? '.4rem' : '0')};
    transition: height 0.4s ease-in-out, margin-top 0.4s ease-in-out;
  }

  & > img {
    display: block;
    width: 100%;
    height: auto;
  }

  & > #chest-top-item-discovered {
    transform: ${({ open }) => (open ? 'scale(1, .6)' : 'scale(1, 1)')};
    transition: transform 0.4s ease-in-out;
  }
`
