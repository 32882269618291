import { useState, useEffect, useContext } from 'react'
import EasterEggContext from './context-providers/easter-egg/context/context.js'
import Loader from './loader/loader.js'
import EasterEgg from './easter-egg/easter-egg.js'
import ItemBag from './item-bag/item-bag.js'
import Header from './header/header.js'
import Main from './main/main.js'
import Footer from './footer/footer.js'
import easterEggs from '../easter-eggs/easter-eggs.js'

const printToConsole = (messages) => {
  messages.forEach((msg) => {
    const { log, style } = msg
    console.log(log, style)
  })
}

const App = () => {
  const { completedStep1, completedStep2, completedStep3, completedStep4, completedStep5 } =
    useContext(EasterEggContext)
  const [loading, setLoading] = useState(true)
  const [loadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false)
    }

    if (document.readyState === 'complete') {
      handleLoad()
    } else {
      window.addEventListener('load', handleLoad)

      return () => {
        window.removeEventListener('load', handleLoad)
      }
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      if (completedStep5) {
        printToConsole(easterEggs.complete)
      } else if (completedStep4) {
        printToConsole(easterEggs.egg4)
      } else if (completedStep3) {
        printToConsole(easterEggs.egg3)
      } else if (completedStep2) {
        printToConsole(easterEggs.egg2)
      } else if (completedStep1) {
        printToConsole(easterEggs.egg1)
      } else {
        printToConsole(easterEggs.start)
      }
    }
  }, [loading, completedStep1, completedStep2, completedStep3, completedStep4, completedStep5])

  return (
    <>
      <Loader setLoadingComplete={setLoadingComplete} />

      {!loading && (
        <>
          <EasterEgg />
          <ItemBag />
          <Header />
          <Main loadingComplete={loadingComplete} />
          <Footer />
        </>
      )}
    </>
  )
}

export default App
