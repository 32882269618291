import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: #eeeeee;
  display: block;
  width: 4rem;
  padding: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-sizing: border-box;
  position: fixed;
  top: 65%;
  right: ${({ completedStep2 }) => (completedStep2 ? '0' : '-7rem')};
  transform: translate(0, 0);
  z-index: 999;
  transition: right 0.5s ease-in-out;

  & > div {
    background-color: #dddddd;
  }

  & > div:not(:first-child) {
    margin-top: 0.5rem;
  }
`

export const ItemContainer = styled.div`
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  position: relative;

  & > div:nth-child(1) {
    display: inline-block;
    font-family: 'Bungee', cursive;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: #555;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 0.4s ease-in-out;
  }

  & > div#egg-item-unknown {
    transform: ${({ completedStep4 }) =>
      completedStep4 ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  }

  & > div#chest-item-unknown {
    transform: ${({ completedStep3 }) =>
      completedStep3 ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  }

  & > div#key-item-unknown {
    transform: ${({ completedStep2 }) =>
      completedStep2 ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  }

  & div.item-container {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 999;
    cursor: ${({ completedStep5 }) => (completedStep5 ? 'pointer' : 'grab')};

    &:active {
      cursor: ${({ completedStep5 }) => (completedStep5 ? 'pointer' : 'grabbing')};

      & #egg-charged-item-discovered {
        transition: none;
        transform: ${({ completedStep5 }) =>
          completedStep5 ? 'translate(-50%, -50%) scale(.9)' : 'translate(-50%, -50%) scale(0)'};
      }
    }
  }

  & #egg-charged-item-discovered,
  & #egg-uncharged-item-discovered,
  & #chest-item-discovered,
  & #key-item-discovered {
    display: block;
    width: 2.2rem;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.4s ease-in-out;
    pointer-events: none;
  }

  & #egg-charged-item-discovered {
    width: 1.8rem;
    transform: ${({ completedStep5 }) =>
      completedStep5 ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
    pointer-events: ${({ completedStep5 }) => (completedStep5 ? 'auto' : 'none')};
  }

  & #egg-uncharged-item-discovered {
    width: 1.8rem;
    transform: ${({ completedStep4, completedStep5 }) =>
      completedStep4 && !completedStep5
        ? 'translate(-50%, -50%) scale(1)'
        : 'translate(-50%, -50%) scale(0)'};
  }

  & #chest-item-discovered {
    transform: ${({ completedStep3, isHoveringChest }) =>
      completedStep3
        ? isHoveringChest
          ? 'translate(-50%, -50%) scale(1.2)'
          : 'translate(-50%, -50%) scale(1)'
        : 'translate(-50%, -50%) scale(0)'};
  }

  & #key-item-discovered {
    width: 2.2rem;
    transform: ${({ completedStep2 }) =>
      completedStep2 ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
  }
`
