/* eslint-disable react/prop-types */
import { Suspense, useContext } from 'react'
import SceneAnimation from '../../context-providers/scene-animation/context/context.js'
import { Canvas } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import ModelCasual1StandingIdle1 from '../../../models/marcoprouve-casual-1-standing-idle-1.js'
import getRadians from '../../../utils/functions/get-radians.js'

const Models = ({ ...props }) => {
  const { animateAboutMe } = props

  return (
    <group {...props} dispose={null}>
      <ModelCasual1StandingIdle1 animateAboutMe={animateAboutMe} />
    </group>
  )
}

const AboutMeScene = ({ scaleDownScene }) => {
  const { animateAboutMe } = useContext(SceneAnimation)

  return (
    <Canvas
      gl={{
        // powerPreference: 'high-performance',
        stencil: false,
        antialias: false
      }}
      dpr={[1, 1.5]}
      camera={{ position: [2, 0, 0], fov: 15, near: 0.01, far: 3 }}
      style={{
        width: '100%',
        height: '100%',
        borderTopRightRadius: '1.5rem',
        borderBottomRightRadius: '1.5rem',
        borderBottomLeftRadius: scaleDownScene ? '1.5rem' : '0',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      }}>
      {/* Lighting */}
      {/* <ambientLight intensity={0.3} /> */}

      {/* Objects */}
      <Suspense fallback={null}>
        <Models
          position={[0, -1.07, -0.01]}
          rotation={[0, getRadians(90), 0]}
          scale={0.7}
          animateAboutMe={animateAboutMe}
        />
        {/* <Environment preset="dawn" /> */}
        <Environment path="/" files="kiara_1_dawn_1k.hdr" />
      </Suspense>
    </Canvas>
  )
}

export default AboutMeScene
