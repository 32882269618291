import { useState, useContext } from 'react'
import Draggable from 'react-draggable'
import ThemeContext from '../context-providers/theme/context/context.js'
import EasterEggContext from '../context-providers/easter-egg/context/context.js'
import Chest from './components/chest/chest.js'
import { MainContainer, ItemContainer } from './styled-components/item-bag.js'

const ItemBag = () => {
  const { lightTheme } = useContext(ThemeContext)
  const {
    completedStep2,
    completedStep3,
    completedStep4,
    completedStep5,
    setCompletedStep4,
    showChargedEgg,
    setShowChargedEgg
  } = useContext(EasterEggContext)
  const [keyPosition, setKeyPosition] = useState({ x: 0, y: 0 })
  const [chestPosition, setChestPosition] = useState({ x: 0, y: 0 })
  const [eggPosition, setEggPosition] = useState({ x: 0, y: 0 })
  const [isHoveringChest, setIsHoveringChest] = useState(false)

  const getIsHoveringChest = (data) => {
    const keyOffsetBoundaries = { x: [-17, 17], y: [-44, -69] }
    const { x, y } = keyOffsetBoundaries

    if (data.x >= x[0] && data.x <= x[1] && data.y <= y[0] && data.y >= y[1]) {
      return true
    } else {
      return false
    }
  }

  const handleKeyDrag = (e, data) => {
    if (getIsHoveringChest(data) && !completedStep4) {
      if (!isHoveringChest) {
        setIsHoveringChest(true)
      }
    } else {
      if (isHoveringChest) {
        setIsHoveringChest(false)
      }
    }
  }

  const handleKeyRelease = (e, data) => {
    if (getIsHoveringChest(data)) {
      if (!completedStep4) setCompletedStep4(true)
    }

    setKeyPosition({ ...keyPosition, x: 0, y: 0 })
    setIsHoveringChest(false)
  }

  const handleChestRelease = () => {
    setChestPosition({ ...keyPosition, x: 0, y: 0 })
  }

  const handleEggRelease = () => {
    setEggPosition({ ...keyPosition, x: 0, y: 0 })
  }

  const handleToggleChargedEgg = () => {
    const showChargedEggTmp = showChargedEgg

    console.log('k')
    setShowChargedEgg(!showChargedEggTmp)
  }

  return (
    <MainContainer completedStep2={completedStep2} lightTheme={lightTheme}>
      <ItemContainer completedStep4={completedStep4} completedStep5={completedStep5}>
        <div id="egg-item-unknown">?</div>

        <Draggable
          disabled={!completedStep4}
          position={eggPosition}
          onStop={(e, data) => handleEggRelease(e, data, { callback_name: 'onStop' })}>
          <div className="item-container">
            <img
              id="egg-uncharged-item-discovered"
              src="https://res.cloudinary.com/marco-prouve/image/upload/v1658449256/portfolio-site/easter-eggs/egg-uncharged-glow_ctz2xy.webp"
              alt="Egg Uncharged"
              width="900"
              height="1180"
              loading="lazy"
              draggable="false"
            />
            <img
              id="egg-charged-item-discovered"
              src="https://res.cloudinary.com/marco-prouve/image/upload/v1658449256/portfolio-site/easter-eggs/egg-charged-glow_mpxkl2.webp"
              alt="Egg Charged"
              width="900"
              height="1180"
              loading="lazy"
              draggable="false"
              onClick={completedStep5 ? handleToggleChargedEgg : () => null}
              onTouchStart={completedStep5 ? handleToggleChargedEgg : () => null}
            />
          </div>
        </Draggable>
      </ItemContainer>

      <ItemContainer completedStep3={completedStep3} isHoveringChest={isHoveringChest}>
        <div id="chest-item-unknown">?</div>

        <Draggable
          disabled={!completedStep3}
          position={chestPosition}
          onStop={(e, data) => handleChestRelease(e, data, { callback_name: 'onStop' })}>
          <div className="item-container">
            <Chest />
          </div>
        </Draggable>
      </ItemContainer>

      <ItemContainer completedStep2={completedStep2}>
        <div id="key-item-unknown">?</div>

        <Draggable
          disabled={!completedStep2}
          position={keyPosition}
          onDrag={
            completedStep3
              ? (e, data) => handleKeyDrag(e, data, { callback_name: 'onDrag' })
              : () => null
          }
          onStop={
            completedStep3
              ? (e, data) => handleKeyRelease(e, data, { callback_name: 'onStop' })
              : () => setKeyPosition({ ...keyPosition, x: 0, y: 0 })
          }>
          <div className="item-container">
            <img
              id="key-item-discovered"
              src="https://res.cloudinary.com/marco-prouve/image/upload/v1658279680/portfolio-site/easter-eggs/key_glbb9u.webp"
              alt="Golden Key"
              width="256"
              height="102"
              loading="lazy"
              draggable="false"
            />
          </div>
        </Draggable>
      </ItemContainer>
    </MainContainer>
  )
}

export default ItemBag
