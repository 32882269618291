import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: ${({ lightTheme }) => (lightTheme ? '#ffffff' : '#26222a')};
  display: block;
  width: 20rem;
  padding: 7rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: fixed;
  top: 0rem;
  right: ${({ open }) => (open ? '0' : '-20rem')};
  bottom: 0;
  z-index: -1;
  transition: right 0.5s ease-in-out;

  @media (max-width: 700px) {
    padding: 6.2rem 2rem 2rem 2rem;
  }

  @media (max-width: 450px) {
    width: 100%;
    right: ${({ open }) => (open ? '0' : '-100%')};
  }
`

export const NavigationLinksContainer = styled.div`
  display: block;

  & > div > div {
    color: ${({ lightTheme }) => (lightTheme ? '#000000' : '#ffffff')};
  }
`

export const NavigationLink = styled.div`
  display: inline-block;
  padding: 0 0.1rem;
  margin-bottom: 0.2rem;
  font-family: 'Bungee', cursive;
  font-size: 1.3rem;
  font-weight: 100;
  line-height: 1.6rem;
  cursor: pointer;
  position: relative;

  & > div {
    display: block;
    width: 0;
    opacity: 0;
    border-bottom: 3.5px solid #ff7777;
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    transition: width 0.3s ease-out, opacity 0.5s ease-in-out;
  }

  &:hover {
    & > div {
      width: 100%;
      opacity: 1;
    }
  }
`

export const ActionsContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0 1.5rem;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 1.5rem;
`

export const ThemeContainer = styled.div`
  display: block;
  width: 10rem;
  height: 6rem;
  padding: 1rem;
  margin: 0 auto;
  position: relative;
`

export const PathContainer = styled.div`
  display: block;
  width: 8rem;
  height: 4rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const CurvedPath = styled.div`
  display: block;
  width: 8rem;
  height: 8rem;
  border: ${({ lightTheme }) => (lightTheme ? '3px solid #00000033' : '3px solid #ffffff33')};
  border-radius: 50%;
`

export const MoonBox = styled.div`
  display: block;
  width: 7.8rem;
  height: 7.8rem;
  position: absolute;
  top: 1.1rem;
  left: 1.1rem;
  transform: ${({ lightTheme }) => (lightTheme ? 'rotate(85deg)' : 'rotate(-85deg)')};
  transition: transform 0.5s ease-in-out;
`

export const MoonIconContainer = styled.div`
  background-color: #eee;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: ${({ lightTheme }) =>
    lightTheme ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  transition: transform 0.5s ease-in-out;

  & > svg {
    display: block;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(85deg);
    transition: transform 0.5s ease-in-out;
  }
`

export const SunBox = styled.div`
  display: block;
  width: 7.8rem;
  height: 7.8rem;
  position: absolute;
  top: 1.1rem;
  left: 1.1rem;
  transform: ${({ lightTheme }) => (lightTheme ? 'rotate(85deg)' : 'rotate(-85deg)')};
  transition: transform 0.5s ease-in-out;
`

export const SunIconContainer = styled.div`
  background-color: #eee;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: ${({ lightTheme }) =>
    lightTheme ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
  transition: transform 0.5s ease-in-out;

  & > svg {
    display: block;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-85deg);
    transition: transform 0.5s ease-in-out;
  }
`

export const ThemeButton = styled.div`
  background-color: #eee;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  & > svg {
    display: block;
    margin-left: ${({ lightTheme }) => (lightTheme ? '1px' : '0')};
    font-size: 1.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }

  & > svg:nth-child(1) {
    transform: ${({ lightTheme }) =>
      lightTheme ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
  }

  & > svg:nth-child(2) {
    transform: ${({ lightTheme }) =>
      lightTheme ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1)'};
  }

  &:hover {
    background-color: #ddd;

    & > svg:nth-child(1) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translate(-50%, -50%) scale(1.15)' : 'translate(-50%, -50%) scale(0)'};
    }

    & > svg:nth-child(2) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1.15)'};
    }
  }

  &:active {
    background-color: #ccc;

    & > svg:nth-child(1) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translate(-50%, -50%) scale(1.07)' : 'translate(-50%, -50%) scale(0)'};
    }

    & > svg:nth-child(2) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translate(-50%, -50%) scale(0)' : 'translate(-50%, -50%) scale(1.07)'};
    }
  }
`

export const Signature = styled.p`
  display: block;
  margin-top: 1.2rem;
  padding: 0 1.5rem 0.8rem 1.5rem;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #777;
`

export const SocialLink = styled.img`
  display: inline-block;
  width: auto;
  height: 1.2rem;
  margin: 0 0.6rem;
  margin-bottom: 0.2rem;
  vertical-align: middle;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`
