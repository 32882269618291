import styled from 'styled-components'
import { animations } from './animations/animations.js'

export const MainContainer = styled.div`
  ${'' /* background-color: #26222a; */}
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  -webkit-backface-visibility: hidden;
  animation-name: ${({ isLoading }) => (isLoading ? 'none' : animations.hideLoader)};
  animation-duration: 2s;
  animation-fill-mode: forwards;
`

export const RoundedSquare = styled.div`
  background-color: #fff;
  display: block;
  width: 10rem;
  height: 10rem;
  border-radius: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 9999px #ffffff;
  animation-name: ${({ isLoading }) => (isLoading ? 'none' : animations.expandRoundedSquare)};
  animation-duration: 2s;
  animation-fill-mode: forwards;
`

export const LoaderContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SquaresContainer = styled.div`
  display: block;
  width: 3.7rem;
  height: 3.7rem;
  margin: 0 auto;
  position: relative;
  animation-name: ${({ isLoading }) => (isLoading ? 'none' : animations.fadeOutLoading)};
  animation-duration: 2s;
  animation-fill-mode: forwards;

  & > div {
    display: block;
    width: 26px;
    height: 26px;
    border-style: solid;
    border-width: 7px;
    border-radius: 0.3rem;
    position: absolute;
    transition: all 0.5s ease-in-out;
    animation-iteration-count: infinite;
  }

  & > div:nth-child(1) {
    border-color: #02bfff;
    top: 0;
    left: 0;
    animation: ${animations.changeSquare1Color} 2s infinite;
  }

  & > div:nth-child(2) {
    border-color: #02bfff;
    top: 0;
    right: 0;
    animation: ${animations.changeSquare2Color} 2s infinite;
  }

  & > div:nth-child(3) {
    border-color: #02bfff;
    bottom: 0;
    left: 0;
    transform: scale(0.85);
    animation: ${animations.changeSquare3Color} 2s infinite;
  }

  & > div:nth-child(4) {
    border-color: #02bfff;
    bottom: 0;
    right: 0;
    animation: ${animations.changeSquare4Color} 2s infinite;
  }
`

export const LoadingTextContainer = styled.div`
  display: block;
  margin-top: 1.2rem;
  text-align: center;
  animation-name: ${({ isLoading }) => (isLoading ? 'none' : animations.fadeOutLoading)};
  animation-duration: 2s;
  animation-fill-mode: forwards;

  & > span {
    color: #26222a;
  }

  ${
    '' /* & > span:nth-child(1) {
    animation: ${animations.fadeOutSquares} 3s infinite;
  }

  & > span:nth-child(2) {
    animation: ${animations.fadeOutSquares} 3s infinite;
  }

  & > span:nth-child(3) {
    animation: ${animations.fadeOutSquares} 3s infinite;
  } */
  }

  & > span:nth-child(8) {
    opacity: 0;
    animation: ${animations.fadeEllipsis1} 2s infinite;
  }

  & > span:nth-child(9) {
    opacity: 0;
    animation: ${animations.fadeEllipsis2} 2s infinite;
  }

  & > span:nth-child(10) {
    opacity: 0;
    animation: ${animations.fadeEllipsis3} 2s infinite;
  }
`

export const LoadingTextLetter = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-family: 'Bungee', cursive;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1.5rem;
`
