import { keyframes } from 'styled-components'

const hideLoader = keyframes`
    0% {
        visibility: visible;
        opacity: 1;
    }
    98% {
        visibility: visible;
        opacity: 1;
    }
    99% {
        visibility: visible;
        opacity: 0;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
`

const expandRoundedSquare = keyframes`
    0% {
        background-color: #ffffffff;
        width: 10rem;
        height: 10rem;
        border-radius: 1.5rem;
    }
    30% {
        background-color: #ffffffff;
        width: 10rem;
        height: 10rem;
        border-radius: 1.5rem;
    }
    ${
      '' /* 50% {
        background-color: #11111144;
        width: 16rem;
        height: 16rem;
        border-radius: 2rem;
    }
    65% {
        background-color: #11111144;
        width: 16rem;
        height: 16rem;
        border-radius: 2rem;
    } */
    }
    99% {
        background-color: #ffffff00;
        width: 105%;
        height: 105%;
        border-radius: 1rem;
    }
    100% {
        border-radius: 0rem;
    }
`

const fadeOutLoading = keyframes`
    0% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`

const changeSquare1Color = keyframes`
    0% {
        border-color: #02bfff;
        transform: scale(1);
    }
    25% {
        border-color: #ff6a6a;
        transform: scale(.8);
    }
    50% {
        border-color: #02bfff;
        transform: scale(1);
    }
    100% {
        border-color: #02bfff;
        transform: scale(1);
    }
`

const changeSquare2Color = keyframes`
    0% {
        border-color: #02bfff;
        transform: scale(1);
    }
    25% {
        border-color: #02bfff;
        transform: scale(1);
    }
    50% {
        border-color: #ff6a6a;
        transform: scale(.8);
    }
    75% {
        border-color: #02bfff;
        transform: scale(1);
    }
`

const changeSquare3Color = keyframes`
    0% {
        border-color: #ff6a6a;
        transform: scale(.8);
    }
    25% {
        border-color: #02bfff;
        transform: scale(1);
    }
    75% {
        border-color: #02bfff;
        transform: scale(1);
    }
    100% {
        border-color: #ff6a6a;
        transform: scale(.8);
    }
`

const changeSquare4Color = keyframes`
    0% {
        border-color: #02bfff;
        transform: scale(1);
    }
    50% {
        border-color: #02bfff;
        transform: scale(1);
    }
    75% {
        border-color: #ff6a6a;
        transform: scale(.8);
    }
    100% {
        border-color: #02bfff;
        transform: scale(1);
    }
`

const fadeEllipsis1 = keyframes`
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`

const fadeEllipsis2 = keyframes`
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`

const fadeEllipsis3 = keyframes`
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`

export const animations = {
  hideLoader,
  expandRoundedSquare,
  fadeOutLoading,
  changeSquare1Color,
  changeSquare2Color,
  changeSquare3Color,
  changeSquare4Color,
  fadeEllipsis1,
  fadeEllipsis2,
  fadeEllipsis3
}
