/* eslint-disable react/prop-types */
import styled from 'styled-components'

const SquaresContainer = styled.div`
  display: block;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 1rem;
  }
`

const Square = styled.div`
  background-color: ${({ selected, activeColor }) => (selected ? `${activeColor}22` : 'none')};
  display: inline-block;
  width: ${({ size }) => size || '15px'};
  height: ${({ size }) => size || '15px'};
  vertical-align: middle;
  border: ${({ color, borderWidth, activeColor, selected }) =>
    selected ? `${borderWidth} solid ${activeColor || color}` : `${borderWidth} solid ${color}`};
  border-radius: 0.2rem;
  transition: all 0.3s ease-in-out;
`

const Squares = (props) => {
  const {
    identifier,
    count,
    color,
    size,
    activeSquare,
    activeColor,
    borderWidth,
    handleClick,
    style,
    squareStyle
  } = props
  const squares = []

  for (let i = 0; i < count; i++) {
    squares.push(
      <Square
        key={`squares-${identifier}-${i}`}
        color={color || '#000'}
        size={size}
        borderWidth={borderWidth || '4px'}
        activeColor={activeColor || '#000'}
        selected={activeSquare === i}
        style={squareStyle || {}}
        onClick={() => handleClick(i)}
      />
    )
  }

  return <SquaresContainer style={style || {}}>{squares}</SquaresContainer>
}

export default Squares
